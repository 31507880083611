.title{

  color: color(second);
  font-family: $title-font;
  font-weight: 400;
  text-transform: uppercase;

  &--main{

    font-size: 3.2rem;
    line-height: 4rem;

    @include after-in(sm){

      font-size: 4rem;
      line-height: 4.8rem;
    }

    @include after-in(md){

      font-size: 4.8rem;
      line-height: 5.6rem;
      text-transform: uppercase;
    }
    @include after-in(lg){

      font-size: 5.6rem;
      line-height: 6.4rem;
    }
  }

  &--second {

    font-size: 2.4rem;
    line-height: 3.2rem;

    @include after-in(sm){

      font-size: 3.2rem;
      line-height: 4rem;
    }

    @include after-in(md){

      font-size: 4rem;
      line-height: 4.8rem;
    }
  }

  &--third{

    font-size: 2.1rem;
    line-height: 3.2rem;

    @include after-in(sm){

      font-size: 2.4rem;
    }

    @include after-in(md){

      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  &--fourth {

    font-size: 1.8rem;
    line-height: 2.4rem;

    @include after-in(sm){

      font-size: 2.1rem;
    }

    @include after-in(md){

      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  &--googlemap {

  }

  &--darker {

    color: color(third);
  }

  &--grey{

    color: color(dark);
  }

  &--center {

    text-align: center;
  }

  &--texted {

    font-family: $text-font;
    font-weight: 300;
  }

  &--shadow{

    padding: $gut*2 0;

    position: relative;

    @include after-in(md) {

      padding: $gut*4 0;
    }

    &::before {

      content: attr(data-text);
      display: block;

      color: color(lighter);
      white-space: nowrap;

      position: absolute;
      left: 0;
      z-index: -1;
      font-family: $text-font;
      font-weight: 900;
      text-transform: lowercase;

      transform-origin: left center;
      transform: translate(0,$gut/2) scale(3);
    }

    @include after-in(sm) {

      &::before {

        transform: translate(0,$gut) scale(5.5);
      }
    }

    @include after-in(md) {

      &::before {

        transform: translate(0,$gut) scale(6);
      }
    }
  }
}
