input, textarea, select {

  display: block;
  width: 100%;
  height: $gut*2;

  padding: $gut/2;
  margin: 0;
  border: 0;

  background: none;
  border-radius: 0;
  box-shadow: none;
  outline: 0;
  appearance: none;

  font-family: $text-font;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: color(main);

  &:focus {

    outline: 0;
  }

  &::placeholder {

    color: inherit;
    opacity: .5;
    font-style: italic;
  }
}

textarea {

  height: $gut*6;
  resize: none;
}


input {

  // &[type=submit] {
  //
  //   width: auto;
  // }

  // &[type=search] {
  //
  // }
}

label {

  display: block;
  width: 100%;
  // height: $gut*2;
  padding: $gut/2;

  cursor: pointer;

  font-family: $text-font;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: color(main);
}
