&-value{

  &-list{

    @extend %grid;

    @include after-in(sm) {
      margin-top: $gut;
    }
    // @include after-in(md) {
    //   margin-top: $gut*2;
    // }
    @include after-in(lg) {
      margin-top: $gut*2;
    }
  }

  &-title{

    padding: $gut/3*2 0;
    color: color(third);
  }

  &-item{

    @include before-out(sm){

      & + &{

        margin-top: $gut*2;
      }
    }

    @include after-in(sm){

      grid-column: 1/3;

      & + & {

        margin-top: -20%;
      }

      &:nth-child(even){

        grid-column: 3/5;
        grid-row-start: 2;
      }

      &:last-child{

        grid-row-start: 3;
      }
    }

    @include after-in(md){

      & + &{

        margin-top: 0;
      }

      &:nth-child(even){

        grid-column: 5/7;
        grid-row-start: 1;
        margin-top: 40%;
      }

      &:last-child{

        grid-column: 2/4;
      }
    }

    @include after-in(lg){

      &:nth-child(even){

        margin-top: 20%;
      }
    }
  }

  &-wrapper{

    @include after-in(sm){

      padding-left: $gut/3*2;
      padding-right: $gut/3*2;
    }
  }

  &-baseline{

    margin-top: $gut/3;
  }
}
