//// ---- PATHS ---- \\\\\

  $img: "../img/";
  $suffix: "_it-anywhere";
  $svg: ".svg";
  $jpg: ".jpg";
  $png: ".png";


//// ---- COLORS ---- \\\\\

  $colors: (

    main:                 #555,
    second:               #277BB7,
    third:                #145F95,

    succeed:              #2ecc71,
    warning:              orange,
    alert:                tomato,

    white:                #fff,
    black:                #000,
    darker:               #1F1F1F,
    dark:                 #353535,
    grey:                 #aaa,
    light:                #ECECEC,
    lighter:              #F7F7F7
  );

  $tags: (

    red:          #FF5E00,
    light-blue:   #259CC5,
    dark-blue:    #1A19BD,
    orange:       #FFAE00,
    cyan:         #1CB997,
    pink:         #D73790,
    green:        #05c46b
  );


//// ---- SIZE ---- \\\\\

  $sizes: (
    base: .8rem,
    line-height: 2.4rem
  );

  $header-height-desk: 7.2rem;

  $x-gut: 40rem;
  $footer-sticky: 5.6rem;


//// ---- Z-INDEX ---- \\\\\

  $z-header:               100;
  $z-nav:                  110;
  $z-lang:                 120;
  $z-curtain:              2000;

//// ---- EASINGS ---- \\\\\

  $easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
  $easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
  $easeInOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);

  $easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  $easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  $easeInOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

  $easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  $easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  $easeInOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);

  $easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  $easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
  $easeInOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);

  $easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  $easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
  $easeInOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

  $easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  $easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
  $easeInOutExpo: cubic-bezier(0.19, 1, 0.22, 1);

  $easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  $easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
  $easeInOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);

  $easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  $easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  $easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

  // $easeInOutFast: cubic-bezier(1,0,0,1);
  // $easeInOutFast: cubic-bezier(1,0,.25,1);
  // $easeInOutFast: cubic-bezier(.77,0,.18,1);
  // $easeInOutFast: cubic-bezier(1,0,.5,1);
  // $easeInOutFast: cubic-bezier(.7,0,.55,1);

  // $authenticMotion: cubic-bezier(.4,0,.2,1);
  // $authenticMotion: cubic-bezier(.4,0,.15,1);
  $authenticMotion: cubic-bezier(.25,0,0,1);

  $easeInOutWe: cubic-bezier(.85,.2,.5,1);

  $easeInOutMoovia: cubic-bezier(.75,.01,.6,1);
  $easeOutInMoovia: cubic-bezier(1,0,.7,1);
  $easeMoovia: cubic-bezier(.7,.4,.55,1);
  $easeOutMoovia: cubic-bezier(0,0,.4,.9);

  // $easeOutNiboo: cubic-bezier(.2,.2,0,1);
  $easeOutNiboo: cubic-bezier(.95,.45,.25,.95);
  // $easeInNiboo: cubic-bezier(1,0,.3,1);
  $easeInNiboo: cubic-bezier(.8,0,.5,1);
  $easeInOutNiboo: cubic-bezier(.5,0,.5,1);

  $easeBackToEase: cubic-bezier(.8,-.3,.45,1);

  $easeInWe: cubic-bezier(.85,0,.5,1);
  $easeOutWe: cubic-bezier(.5,0,.15,1);

  $easeInOutGipi: cubic-bezier(.6,0,.2,1);

  $easeOutBounce: cubic-bezier(.75,0,.42,1.33);

  $easeInOutAdj: cubic-bezier(.84,0,.16,1);
  $backInOutAdj: cubic-bezier(.84,-.2,.16,1.2);

  // $test: cubic-bezier(.8,0,.2,1);
