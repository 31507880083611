@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  border: 0;
  padding: 0;
  background: none; }

a {
  color: inherit; }

input, textarea, button {
  appearance: none; }

.line {
  align-content: flex-start; }
  .line--right {
    justify-content: flex-end; }
  .line--left {
    justify-content: flex-start; }
  .line--bottom {
    align-content: flex-end;
    align-items: flex-end; }
  .line--center {
    justify-content: center; }
  .line--justify {
    justify-content: space-between; }
  .line--v-center {
    align-content: center;
    align-items: center; }
  .line--inline {
    display: inline-flex; }
  .line--col {
    flex-direction: column; }
  .line--guts {
    margin-left: -3.2rem;
    margin-right: -3.2rem; }
    @media screen and (max-width: 1024px) {
      .line--guts {
        margin-left: -1.6rem;
        margin-right: -1.6rem; } }
    .line--guts > * {
      padding-left: 3.2rem;
      padding-right: 3.2rem; }
      @media screen and (max-width: 1024px) {
        .line--guts > * {
          padding-left: 1.6rem;
          padding-right: 1.6rem; } }
    .line--guts[class^="flx"], .line--guts[class*=" flx"] {
      margin-left: 0;
      margin-right: 0; }
  .line--full > * {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .line--auto > *  {
    flex: 0 0 auto; }

.itm--center {
  align-self: center; }

.itm--bottom {
  align-self: flex-end; }

.itm--top {
  align-self: flex-start; }

.itm--first {
  order: -1; }

.grid-itm {
  grid-column: 1; }
  .grid-itm--c {
    justify-self: center; }
  @media screen and (min-width: 768px) {
    .grid-itm--text {
      padding-right: 1.6rem;
      padding-left: 1.6rem;
      grid-column: 3/5; } }
  .grid-itm--full {
    grid-column: 1 / 4; }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .grid-itm--full {
        grid-column: 1 / 7; } }
    @media screen and (min-width: 1024px) {
      .grid-itm--full {
        grid-column: 1 / 9; } }
  .grid-itm--expend {
    width: 100vw;
    justify-self: center;
    grid-column: 1 / 4; }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .grid-itm--expend {
        grid-column: 1 / 7; } }
    @media screen and (min-width: 1024px) {
      .grid-itm--expend {
        grid-column: 1 / 9; } }
  .grid-itm--center {
    grid-column: 2/3; }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .grid-itm--center {
        grid-column: 2 / 6; } }
    @media screen and (min-width: 1024px) {
      .grid-itm--center {
        grid-column: 2 / 8; } }

@media screen and (min-width: 0px) {
  .flx-xxxs-auto {
    flex: 0 0 auto; }
  .ml-xxxs-1 {
    margin-left: 8.33333%; }
  .flx-xxxs-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .ml-xxxs-2 {
    margin-left: 16.66667%; }
  .flx-xxxs-3 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%; }
  .ml-xxxs-3 {
    margin-left: 25%; }
  .flx-xxxs-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .ml-xxxs-4 {
    margin-left: 33.33333%; }
  .flx-xxxs-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .ml-xxxs-5 {
    margin-left: 41.66667%; }
  .flx-xxxs-6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
  .ml-xxxs-6 {
    margin-left: 50%; }
  .flx-xxxs-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .ml-xxxs-7 {
    margin-left: 58.33333%; }
  .flx-xxxs-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .ml-xxxs-8 {
    margin-left: 66.66667%; }
  .flx-xxxs-9 {
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%; }
  .ml-xxxs-9 {
    margin-left: 75%; }
  .flx-xxxs-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .ml-xxxs-10 {
    margin-left: 83.33333%; }
  .flx-xxxs-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .ml-xxxs-11 {
    margin-left: 91.66667%; }
  .flx-xxxs-12 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .ml-xxxs-12 {
    margin-left: 100%; } }

@media screen and (min-width: 375px) {
  .flx-xxsmid-auto {
    flex: 0 0 auto; }
  .ml-xxsmid-1 {
    margin-left: 8.33333%; }
  .flx-xxsmid-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .ml-xxsmid-2 {
    margin-left: 16.66667%; }
  .flx-xxsmid-3 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%; }
  .ml-xxsmid-3 {
    margin-left: 25%; }
  .flx-xxsmid-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .ml-xxsmid-4 {
    margin-left: 33.33333%; }
  .flx-xxsmid-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .ml-xxsmid-5 {
    margin-left: 41.66667%; }
  .flx-xxsmid-6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
  .ml-xxsmid-6 {
    margin-left: 50%; }
  .flx-xxsmid-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .ml-xxsmid-7 {
    margin-left: 58.33333%; }
  .flx-xxsmid-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .ml-xxsmid-8 {
    margin-left: 66.66667%; }
  .flx-xxsmid-9 {
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%; }
  .ml-xxsmid-9 {
    margin-left: 75%; }
  .flx-xxsmid-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .ml-xxsmid-10 {
    margin-left: 83.33333%; }
  .flx-xxsmid-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .ml-xxsmid-11 {
    margin-left: 91.66667%; }
  .flx-xxsmid-12 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .ml-xxsmid-12 {
    margin-left: 100%; } }

@media screen and (min-width: 440px) {
  .flx-xxs-auto {
    flex: 0 0 auto; }
  .ml-xxs-1 {
    margin-left: 8.33333%; }
  .flx-xxs-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .ml-xxs-2 {
    margin-left: 16.66667%; }
  .flx-xxs-3 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%; }
  .ml-xxs-3 {
    margin-left: 25%; }
  .flx-xxs-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .ml-xxs-4 {
    margin-left: 33.33333%; }
  .flx-xxs-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .ml-xxs-5 {
    margin-left: 41.66667%; }
  .flx-xxs-6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
  .ml-xxs-6 {
    margin-left: 50%; }
  .flx-xxs-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .ml-xxs-7 {
    margin-left: 58.33333%; }
  .flx-xxs-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .ml-xxs-8 {
    margin-left: 66.66667%; }
  .flx-xxs-9 {
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%; }
  .ml-xxs-9 {
    margin-left: 75%; }
  .flx-xxs-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .ml-xxs-10 {
    margin-left: 83.33333%; }
  .flx-xxs-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .ml-xxs-11 {
    margin-left: 91.66667%; }
  .flx-xxs-12 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .ml-xxs-12 {
    margin-left: 100%; } }

@media screen and (min-width: 560px) {
  .flx-xsmid-auto {
    flex: 0 0 auto; }
  .ml-xsmid-1 {
    margin-left: 8.33333%; }
  .flx-xsmid-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .ml-xsmid-2 {
    margin-left: 16.66667%; }
  .flx-xsmid-3 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%; }
  .ml-xsmid-3 {
    margin-left: 25%; }
  .flx-xsmid-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .ml-xsmid-4 {
    margin-left: 33.33333%; }
  .flx-xsmid-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .ml-xsmid-5 {
    margin-left: 41.66667%; }
  .flx-xsmid-6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
  .ml-xsmid-6 {
    margin-left: 50%; }
  .flx-xsmid-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .ml-xsmid-7 {
    margin-left: 58.33333%; }
  .flx-xsmid-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .ml-xsmid-8 {
    margin-left: 66.66667%; }
  .flx-xsmid-9 {
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%; }
  .ml-xsmid-9 {
    margin-left: 75%; }
  .flx-xsmid-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .ml-xsmid-10 {
    margin-left: 83.33333%; }
  .flx-xsmid-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .ml-xsmid-11 {
    margin-left: 91.66667%; }
  .flx-xsmid-12 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .ml-xsmid-12 {
    margin-left: 100%; } }

@media screen and (min-width: 640px) {
  .flx-xs-auto {
    flex: 0 0 auto; }
  .ml-xs-1 {
    margin-left: 8.33333%; }
  .flx-xs-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .ml-xs-2 {
    margin-left: 16.66667%; }
  .flx-xs-3 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%; }
  .ml-xs-3 {
    margin-left: 25%; }
  .flx-xs-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .ml-xs-4 {
    margin-left: 33.33333%; }
  .flx-xs-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .ml-xs-5 {
    margin-left: 41.66667%; }
  .flx-xs-6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
  .ml-xs-6 {
    margin-left: 50%; }
  .flx-xs-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .ml-xs-7 {
    margin-left: 58.33333%; }
  .flx-xs-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .ml-xs-8 {
    margin-left: 66.66667%; }
  .flx-xs-9 {
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%; }
  .ml-xs-9 {
    margin-left: 75%; }
  .flx-xs-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .ml-xs-10 {
    margin-left: 83.33333%; }
  .flx-xs-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .ml-xs-11 {
    margin-left: 91.66667%; }
  .flx-xs-12 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .ml-xs-12 {
    margin-left: 100%; } }

@media screen and (min-width: 768px) {
  .flx-sm-auto {
    flex: 0 0 auto; }
  .ml-sm-1 {
    margin-left: 8.33333%; }
  .flx-sm-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .ml-sm-2 {
    margin-left: 16.66667%; }
  .flx-sm-3 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%; }
  .ml-sm-3 {
    margin-left: 25%; }
  .flx-sm-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .ml-sm-4 {
    margin-left: 33.33333%; }
  .flx-sm-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .ml-sm-5 {
    margin-left: 41.66667%; }
  .flx-sm-6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
  .ml-sm-6 {
    margin-left: 50%; }
  .flx-sm-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .ml-sm-7 {
    margin-left: 58.33333%; }
  .flx-sm-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .ml-sm-8 {
    margin-left: 66.66667%; }
  .flx-sm-9 {
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%; }
  .ml-sm-9 {
    margin-left: 75%; }
  .flx-sm-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .ml-sm-10 {
    margin-left: 83.33333%; }
  .flx-sm-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .ml-sm-11 {
    margin-left: 91.66667%; }
  .flx-sm-12 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .ml-sm-12 {
    margin-left: 100%; } }

@media screen and (min-width: 1024px) {
  .flx-md-auto {
    flex: 0 0 auto; }
  .ml-md-1 {
    margin-left: 8.33333%; }
  .flx-md-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .ml-md-2 {
    margin-left: 16.66667%; }
  .flx-md-3 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%; }
  .ml-md-3 {
    margin-left: 25%; }
  .flx-md-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .ml-md-4 {
    margin-left: 33.33333%; }
  .flx-md-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .ml-md-5 {
    margin-left: 41.66667%; }
  .flx-md-6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
  .ml-md-6 {
    margin-left: 50%; }
  .flx-md-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .ml-md-7 {
    margin-left: 58.33333%; }
  .flx-md-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .ml-md-8 {
    margin-left: 66.66667%; }
  .flx-md-9 {
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%; }
  .ml-md-9 {
    margin-left: 75%; }
  .flx-md-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .ml-md-10 {
    margin-left: 83.33333%; }
  .flx-md-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .ml-md-11 {
    margin-left: 91.66667%; }
  .flx-md-12 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .ml-md-12 {
    margin-left: 100%; } }

@media screen and (min-width: 1366px) {
  .flx-lg-auto {
    flex: 0 0 auto; }
  .ml-lg-1 {
    margin-left: 8.33333%; }
  .flx-lg-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .ml-lg-2 {
    margin-left: 16.66667%; }
  .flx-lg-3 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%; }
  .ml-lg-3 {
    margin-left: 25%; }
  .flx-lg-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .ml-lg-4 {
    margin-left: 33.33333%; }
  .flx-lg-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .ml-lg-5 {
    margin-left: 41.66667%; }
  .flx-lg-6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
  .ml-lg-6 {
    margin-left: 50%; }
  .flx-lg-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .ml-lg-7 {
    margin-left: 58.33333%; }
  .flx-lg-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .ml-lg-8 {
    margin-left: 66.66667%; }
  .flx-lg-9 {
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%; }
  .ml-lg-9 {
    margin-left: 75%; }
  .flx-lg-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .ml-lg-10 {
    margin-left: 83.33333%; }
  .flx-lg-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .ml-lg-11 {
    margin-left: 91.66667%; }
  .flx-lg-12 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .ml-lg-12 {
    margin-left: 100%; } }

.line {
  display: flex;
  flex-wrap: wrap; }

.grid-ctn, .vtb-value-list, .vtb-heading-hero {
  display: grid;
  grid-template-rows: auto; }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    .grid-ctn, .vtb-value-list, .vtb-heading-hero {
      grid-template-columns: 1fr;
      grid-auto-flow: row; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .grid-ctn, .vtb-value-list, .vtb-heading-hero {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }
  @media screen and (min-width: 1024px) {
    .grid-ctn, .vtb-value-list, .vtb-heading-hero {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; } }

.overflow {
  overflow: hidden; }

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none; }
  *, *:after, *:before {
    box-sizing: border-box; }

img {
  display: block;
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast; }

body {
  min-height: 100vh;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative; }

.main-container {
  width: 100vw;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 0 2.4rem; }
  @media screen and (min-width: 768px) {
    .main-container {
      padding: 0 4.8rem; } }

.main-content {
  width: 100%;
  min-height: 100vh;
  padding-top: 7.2rem;
  position: relative; }

.svg-defs {
  position: fixed;
  top: -100%;
  left: -100%; }

button, button:focus, input, input:focus, textarea, textarea:focus, select, select:focus {
  outline: none; }

::selection {
  background: #277BB7;
  color: #fff; }

.selection--invert::selection, .selection--invert ::selection {
  background: #fff;
  color: #277BB7; }

.link::selection {
  background: #154263;
  color: #fff; }

html, body {
  font-size: 10px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #555; }

.main-container {
  font-size: 1.6rem;
  line-height: 2.4rem; }
  @media screen and (min-width: 768px) {
    .main-container {
      font-size: 1.8rem;
      line-height: 3.2rem; } }

h1, h2, h3, h4, h5, h6 {
  color: inherit; }

a {
  text-decoration: none;
  color: inherit; }
  a.link {
    color: #277BB7;
    border-bottom: 0.1rem solid #277BB7;
    font-weight: 600; }
    a.link--white {
      color: #fff;
      border-color: #fff;
      font-weight: 400; }

strong {
  font-weight: 600; }

em, .italic {
  font-style: italic; }

p {
  color: inherit; }

p + p {
  margin-top: 1.6rem; }

time {
  display: inline-block;
  font: inherit; }

small {
  font-size: 75%; }

.return {
  display: block; }

.hugggy-logo {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  margin-left: auto;
  opacity: 1;
  transform-origin: right center;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1); }
  @media screen and (min-width: 1025px) {
    .hugggy-logo:hover {
      transform: translate(0, -50%) scale(1.1); }
      .hugggy-logo:hover svg path {
        fill: #fff; } }
  .hugggy-logo span {
    display: block;
    width: 0;
    height: 0;
    opacity: 0;
    text-indent: -9999px; }
  .hugggy-logo svg {
    width: 100%;
    height: 100%; }
    .hugggy-logo svg path {
      fill: #555;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1); }

html.ie .main-content, html.ie11 .main-content {
  display: block; }

body.js-active .js-shell {
  opacity: 1;
  visibility: visible;
  transform-origin: top;
  transition: all 0.8s cubic-bezier(0.75, 0.01, 0.6, 1); }
  body.js-active .js-shell.hide-outgoing {
    opacity: 0;
    visibility: hidden; }
  body.js-active .js-shell.hide-coming {
    opacity: 0;
    visibility: hidden; }

body.js-active body.fade {
  height: 100vh !important;
  overflow: hidden !important; }
  body.js-active body.fade, body.js-active body.fade * {
    transition-duration: .8s !important;
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.75, 0.01, 0.6, 1) !important; }

.js-curtain {
  transition-duration: 0.58s !important; }
  @media screen and (min-width: 1024px) {
    .js-curtain {
      transition-duration: 0.64s !important; } }
  .js-curtain span:nth-child(1) {
    transition-delay: 0.03s; }
  .js-curtain span:nth-child(2) {
    transition-delay: 0.06s; }
  .js-curtain span:nth-child(3) {
    transition-delay: 0.09s; }
  .js-curtain span:nth-child(4) {
    transition-delay: 0.12s; }
  .js-curtain span:nth-child(5) {
    transition-delay: 0.15s; }
  .js-curtain span:nth-child(6) {
    transition-delay: 0.18s; }
  .js-curtain span:nth-child(7) {
    transition-delay: 0.21s; }
  .js-curtain span:nth-child(8) {
    transition-delay: 0.24s; }
  .js-curtain.show span::after {
    transform: scale(1, 1) !important; }
  .js-curtain.hide span::after {
    transform: translate(100%, 0) scale(1) !important;
    transition-timing-function: ease; }

body.js-active .js-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ECECEC; }
  body.js-active .js-img img {
    display: none; }

body.js-active *.js-animitem[data-trans="fade"] {
  opacity: 0; }

body.js-active *.js-animitem[data-trans="fade--bottom"] {
  opacity: 0;
  transform: translate3d(0, 14.4rem, 0); }

body.js-active *.js-animitem[data-trans="curtain--bottom"] {
  position: relative;
  opacity: 0;
  transform-origin: center bottom;
  transform: translate3d(0, 14.4rem, 0); }
  body.js-active *.js-animitem[data-trans="curtain--bottom"]::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    transform: scale(1);
    transition-duration: .48s;
    transition-delay: inherit;
    transition-timing-function: cubic-bezier(0.25, 0, 0, 1); }

body.js-active *.js-animitem[data-trans="fade--left"] {
  opacity: 0;
  transform: translate3d(-14.4rem, 0, 0); }

body.js-active *.js-animitem[data-trans="fade--right"] {
  opacity: 0;
  transform: translate3d(14.4rem, 0, 0); }

body.js-active *.js-animitem[data-trans="fade--bottom--itms"] .js-animitem-sub {
  opacity: 0;
  transform: translate3d(0, 14.4rem, 0); }

body.js-active *.js-animitem[data-trans="fade--left--itms"] .js-animitem-sub {
  opacity: 0;
  transform: translate3d(-14.4rem, 0, 0); }

body.js-active *.js-animitem[data-trans="curtain--bottom--itms"] .js-animitem-sub {
  position: relative;
  opacity: 0;
  transform-origin: center bottom;
  transform: translate3d(0, 14.4rem, 0); }
  body.js-active *.js-animitem[data-trans="curtain--bottom--itms"] .js-animitem-sub::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    transform: scale(1);
    transition-duration: .48s;
    transition-delay: inherit;
    transition-timing-function: cubic-bezier(0.25, 0, 0, 1); }

body.js-active *.js-animitem.animated {
  transition-duration: .8s;
  transition-timing-function: cubic-bezier(0.25, 0, 0, 1); }
  body.js-active *.js-animitem.animated .js-animitem-sub {
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.25, 0, 0, 1); }
  body.js-active *.js-animitem.animated[data-trans="fade"] {
    transition-property: opacity;
    opacity: 1;
    transition-duration: .72s; }
  body.js-active *.js-animitem.animated[data-trans="fade--left"], body.js-active *.js-animitem.animated[data-trans="fade--right"] {
    transition-property: transform, opacity;
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  body.js-active *.js-animitem.animated[data-trans="fade--bottom"] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-property: transform, opacity; }
  body.js-active *.js-animitem.animated[data-trans="curtain--bottom"] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-duration: .48s; }
    body.js-active *.js-animitem.animated[data-trans="curtain--bottom"]::before {
      transition-property: transform;
      transform-origin: right top;
      transform: scale(1, 0); }
  body.js-active *.js-animitem.animated[data-trans="curtain--bottom--itms"] .js-animitem-sub {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-duration: .48s; }
    body.js-active *.js-animitem.animated[data-trans="curtain--bottom--itms"] .js-animitem-sub::before {
      transition-property: transform;
      transform-origin: right top;
      transform: scale(1, 0); }
  body.js-active *.js-animitem.animated[data-trans="fade--bottom--itms"] .js-animitem-sub, body.js-active *.js-animitem.animated[data-trans="fade--left--itms"] .js-animitem-sub {
    transition-property: transform, opacity;
    opacity: 1;
    transform: translate3d(0, 0, 0); }

body.js-active .js-prlx {
  position: relative;
  transition: transform 0.1s cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform; }

body.js-active .js-video-vimeo {
  overflow: hidden;
  background: url("https://i.vimeocdn.com/video/736033690.jpg") no-repeat center/cover;
  background: url("../img/home/hero_it-anywhere.jpg") no-repeat center/cover; }
  body.js-active .js-video-vimeo.show iframe {
    opacity: 1; }
  body.js-active .js-video-vimeo iframe {
    width: 100% !important;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: all 0.32s cubic-bezier(0.25, 0, 0, 1) 0.4s; }

body.js-active .vp-player-layout {
  width: 100% !important; }

body.js-active .js-is-focused::after {
  background: #277BB7; }

body.js-active .js-is-focused .vtb-form-label {
  color: #277BB7;
  transform: scale(0.75) translate(0.4rem, -75%); }

body.js-active .js-is-filled .vtb-form-label {
  transform: scale(0.75) translate(0.4rem, -75%); }

body.js-active .js-is-empty::after, body.js-active .js-is-invalid::after {
  background: tomato; }

body.js-active .js-is-empty .vtb-form-label, body.js-active .js-is-invalid .vtb-form-label {
  color: tomato; }

.icon {
  display: inline-block;
  position: relative;
  transition: all 0.48s cubic-bezier(0.25, 0, 0, 1); }
  .icon::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../img/icons/icons_it-anywhere.svg");
    background-size: 1100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    transform-origin: center center; }
  .icon--sm:before, .icon--sm:after {
    transform: scale(0.75); }
  .icon--skill::before {
    background-position: 0% 0%; }
  .icon--security::before {
    background-position: 10% 0%; }
  .icon--database::before {
    background-position: 20% 0%; }
  .icon--settings::before {
    background-position: 30% 0%; }
  .icon--talk::before {
    background-position: 40% 0%; }
  .icon--gear::before {
    background-position: 50% 0%; }

.icon {
  width: 4.8rem;
  height: 4.8rem; }

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 0.8rem 4.8rem;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  font-family: "Pathway Gothic One", "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  transition: all 0.24s cubic-bezier(0.25, 0, 0, 1); }
  @media screen and (min-width: 768px) {
    .btn {
      font-size: 2.1rem;
      padding: 0.8rem 7.2rem; } }
  .btn::before, .btn::after, .btn span::after, .btn span::before {
    content: "";
    display: block;
    width: calc(25% + .1rem);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    transform-origin: left center;
    transform: scale(0, 1);
    transition: all 0.24s cubic-bezier(0.25, 0, 0, 1); }
  .btn::after {
    left: 25%;
    transition-delay: .03s; }
  .btn span::before {
    left: 50%;
    transition-delay: 0.06s; }
  .btn span::after {
    left: 75%;
    transition-delay: 0.09s; }
  @media screen and (max-width: 375px) {
    .btn--grow {
      width: 100%; } }
  .btn--sm {
    padding: 0.8rem 1.2rem;
    font-size: 1.8rem;
    line-height: 2.4rem; }
    .btn--sm span::after {
      width: .8rem;
      height: .8rem; }
  .btn--white {
    color: #353535;
    border: 0.1rem solid #fff; }
    .btn--white::before, .btn--white::after, .btn--white span::after, .btn--white span::before {
      background: #fff;
      transform: scale(1, 1); }
    @media screen and (min-width: 1025px) {
      .btn--white:hover {
        color: #fff; }
        .btn--white:hover::before, .btn--white:hover::after, .btn--white:hover span::after, .btn--white:hover span::before {
          transform: scale(0, 1); } }
    .btn--white.btn--outline {
      color: #fff;
      background: transparent;
      border: 0.1rem solid #fff; }
      .btn--white.btn--outline::before, .btn--white.btn--outline::after, .btn--white.btn--outline span::after, .btn--white.btn--outline span::before {
        transform: scale(0, 1); }
      @media screen and (min-width: 1025px) {
        .btn--white.btn--outline:hover {
          color: #353535; }
          .btn--white.btn--outline:hover::before, .btn--white.btn--outline:hover::after, .btn--white.btn--outline:hover span::after, .btn--white.btn--outline:hover span::before {
            background: #fff;
            transform: scale(1, 1); } }
  .btn--grey {
    color: #fff;
    border: 0.1rem solid #353535; }
    .btn--grey::before, .btn--grey::after, .btn--grey span::after, .btn--grey span::before {
      background: #353535;
      transform: scale(1, 1); }
    @media screen and (min-width: 1025px) {
      .btn--grey:hover {
        color: #353535; }
        .btn--grey:hover::before, .btn--grey:hover::after, .btn--grey:hover span::after, .btn--grey:hover span::before {
          transform: scale(0, 1); } }
    .btn--grey.btn--outline {
      color: #353535;
      background: transparent;
      border: 0.1rem solid #353535; }
      .btn--grey.btn--outline::before, .btn--grey.btn--outline::after, .btn--grey.btn--outline span::after, .btn--grey.btn--outline span::before {
        transform: scale(0, 1); }
      @media screen and (min-width: 1025px) {
        .btn--grey.btn--outline:hover {
          color: #fff; }
          .btn--grey.btn--outline:hover::before, .btn--grey.btn--outline:hover::after, .btn--grey.btn--outline:hover span::after, .btn--grey.btn--outline:hover span::before {
            background: #353535;
            transform: scale(1, 1); } }
  .btn--blue {
    color: #fff;
    border: 0.1rem solid #277BB7; }
    .btn--blue::before, .btn--blue::after, .btn--blue span::after, .btn--blue span::before {
      background: #277BB7;
      transform: scale(1, 1); }
    @media screen and (min-width: 1025px) {
      .btn--blue:hover {
        color: #277BB7; }
        .btn--blue:hover::before, .btn--blue:hover::after, .btn--blue:hover span::after, .btn--blue:hover span::before {
          transform: scale(0, 1); } }
    .btn--blue.btn--outline {
      color: #277BB7;
      background: transparent;
      border: 0.1rem solid #277BB7; }
      .btn--blue.btn--outline::before, .btn--blue.btn--outline::after, .btn--blue.btn--outline span::after, .btn--blue.btn--outline span::before {
        transform: scale(0, 1); }
      @media screen and (min-width: 1025px) {
        .btn--blue.btn--outline:hover {
          color: #fff; }
          .btn--blue.btn--outline:hover::before, .btn--blue.btn--outline:hover::after, .btn--blue.btn--outline:hover span::after, .btn--blue.btn--outline:hover span::before {
            background: #277BB7;
            transform: scale(1, 1); } }
  .btn-wrapper {
    padding: 7.2rem 0 0; }
  .btn-lang {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.8rem;
    height: 4.8rem;
    margin-left: auto;
    margin-right: .8rem;
    background: #ECECEC;
    cursor: pointer;
    color: #145F95;
    font-family: "Pathway Gothic One", "Roboto", Helvetica, Arial, sans-serif;
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;
    position: relative;
    z-index: 120; }
    @media screen and (min-width: 768px) {
      .btn-lang {
        justify-content: flex-start;
        margin-right: 0;
        height: 7.2rem;
        transform: translate(0, -1.2rem); }
        .btn-lang span {
          position: absolute;
          left: 0;
          bottom: 0;
          transform: translate(1.6rem, -1.6rem); } }
  .btn-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.8rem;
    height: 4.8rem;
    background: #277BB7;
    cursor: pointer;
    font-family: "Pathway Gothic One", "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;
    position: relative;
    transition: all 0.32s cubic-bezier(0.25, 0, 0, 1); }
    @media screen and (min-width: 768px) {
      .btn-menu {
        align-items: flex-end;
        align-content: flex-end;
        height: 7.2rem;
        transform: translate(0, -1.2rem); } }
    .btn-menu i {
      transition: inherit; }
    .btn-menu span, .btn-menu i::before, .btn-menu i::after {
      display: block;
      width: 2rem;
      height: .2rem;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: inherit; }
    @media screen and (min-width: 768px) {
      .btn-menu i::before, .btn-menu i::after {
        left: auto;
        right: 0;
        transform: translate(0, -50%); } }
    .btn-menu i::after {
      content: "";
      opacity: 0; }
    .btn-menu i::before {
      content: ""; }
    .btn-menu span {
      background: transparent;
      color: #fff; }
      @media screen and (max-width: 767px) {
        .btn-menu span {
          text-indent: -999.9rem; } }
      @media screen and (min-width: 768px) {
        .btn-menu span {
          display: inline-block;
          width: auto;
          height: auto;
          padding-right: 3.2rem;
          left: 1.6rem;
          top: auto;
          bottom: 1.6rem;
          transform: translate(0, 0); } }
      .btn-menu span::before, .btn-menu span::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        transition: inherit; }
        @media screen and (min-width: 768px) {
          .btn-menu span::before, .btn-menu span::after {
            width: 2rem;
            height: .2rem;
            top: 50%;
            left: auto;
            right: 0; } }
      .btn-menu span::before {
        transform: translate(0, -300%); }
        @media screen and (min-width: 768px) {
          .btn-menu span::before {
            transform: translate(0, -350%); } }
      .btn-menu span::after {
        transform: translate(0, 300%); }
        @media screen and (min-width: 768px) {
          .btn-menu span::after {
            transform: translate(0, 250%); } }

@media screen and (max-width: 767px) {
  .menu-open .btn-menu {
    background: #145F95; } }

.menu-open .btn-menu span::before, .menu-open .btn-menu span::after {
  opacity: 0; }

.menu-open .btn-menu span::before {
  transform: translate(0, -600%); }

.menu-open .btn-menu span::after {
  transform: translate(0, 600%); }

.menu-open .btn-menu i::after, .menu-open .btn-menu i::before {
  transition-delay: .1s; }

.menu-open .btn-menu i::after {
  opacity: 1;
  transform: translate(-50%, -50%) rotate(45deg); }
  @media screen and (min-width: 768px) {
    .menu-open .btn-menu i::after {
      transform: translate(0, -50%) rotate(45deg); } }

.menu-open .btn-menu i::before {
  transform: translate(-50%, -50%) rotate(-45deg); }
  @media screen and (min-width: 768px) {
    .menu-open .btn-menu i::before {
      transform: translate(0, -50%) rotate(-45deg); } }

.title, body.job .vtb-section h2, body.job .vtb-section h3 {
  color: #277BB7;
  font-family: "Pathway Gothic One", "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase; }
  .title--main {
    font-size: 3.2rem;
    line-height: 4rem; }
    @media screen and (min-width: 768px) {
      .title--main {
        font-size: 4rem;
        line-height: 4.8rem; } }
    @media screen and (min-width: 1024px) {
      .title--main {
        font-size: 4.8rem;
        line-height: 5.6rem;
        text-transform: uppercase; } }
    @media screen and (min-width: 1366px) {
      .title--main {
        font-size: 5.6rem;
        line-height: 6.4rem; } }
  .title--second, body.job .vtb-section h2 {
    font-size: 2.4rem;
    line-height: 3.2rem; }
    @media screen and (min-width: 768px) {
      .title--second, body.job .vtb-section h2 {
        font-size: 3.2rem;
        line-height: 4rem; } }
    @media screen and (min-width: 1024px) {
      .title--second, body.job .vtb-section h2 {
        font-size: 4rem;
        line-height: 4.8rem; } }
  .title--third {
    font-size: 2.1rem;
    line-height: 3.2rem; }
    @media screen and (min-width: 768px) {
      .title--third {
        font-size: 2.4rem; } }
    @media screen and (min-width: 1024px) {
      .title--third {
        font-size: 3.2rem;
        line-height: 4rem; } }
  .title--fourth {
    font-size: 1.8rem;
    line-height: 2.4rem; }
    @media screen and (min-width: 768px) {
      .title--fourth {
        font-size: 2.1rem; } }
    @media screen and (min-width: 1024px) {
      .title--fourth {
        font-size: 2.4rem;
        line-height: 3.2rem; } }
  .title--darker {
    color: #145F95; }
  .title--grey {
    color: #353535; }
  .title--center {
    text-align: center; }
  .title--texted {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 300; }
  .title--shadow {
    padding: 4.8rem 0;
    position: relative; }
    @media screen and (min-width: 1024px) {
      .title--shadow {
        padding: 9.6rem 0; } }
    .title--shadow::before {
      content: attr(data-text);
      display: block;
      color: #F7F7F7;
      white-space: nowrap;
      position: absolute;
      left: 0;
      z-index: -1;
      font-family: "Roboto", Helvetica, Arial, sans-serif;
      font-weight: 900;
      text-transform: lowercase;
      transform-origin: left center;
      transform: translate(0, 1.2rem) scale(3); }
    @media screen and (min-width: 768px) {
      .title--shadow::before {
        transform: translate(0, 2.4rem) scale(5.5); } }
    @media screen and (min-width: 1024px) {
      .title--shadow::before {
        transform: translate(0, 2.4rem) scale(6); } }

.ratio {
  display: block;
  width: 100%;
  position: relative; }
  .ratio:after {
    content: "";
    display: block;
    width: 100%; }
  .ratio--square:after {
    padding-top: 100%; }
  .ratio--2x1:after {
    padding-top: 50%; }
  .ratio--5x2:after {
    padding-top: 40%; }
    @media screen and (max-width: 768px) {
      .ratio--5x2:after {
        padding-top: 56.25%; } }
  .ratio--3x1:after {
    padding-top: 33.33333%; }
  .ratio--4x3:after {
    padding-top: 75%; }
  .ratio--16x9:after {
    padding-top: 56.25%; }
  .ratio--third:after {
    padding-top: 50vh; }
    @media screen and (min-width: 768px) {
      .ratio--third:after {
        padding-top: 66.66667vh; } }

input, textarea, select {
  display: block;
  width: 100%;
  height: 4.8rem;
  padding: 1.2rem;
  margin: 0;
  border: 0;
  background: none;
  border-radius: 0;
  box-shadow: none;
  outline: 0;
  appearance: none;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #555; }
  input:focus, textarea:focus, select:focus {
    outline: 0; }
  input::placeholder, textarea::placeholder, select::placeholder {
    color: inherit;
    opacity: .5;
    font-style: italic; }

textarea {
  height: 14.4rem;
  resize: none; }

label {
  display: block;
  width: 100%;
  padding: 1.2rem;
  cursor: pointer;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #555; }

.vtb-section + .vtb-section {
  margin-top: 4.8rem; }
  @media screen and (min-width: 768px) {
    .vtb-section + .vtb-section {
      margin-top: 9.6rem; } }

@media screen and (min-width: 768px) {
  .vtb-section-maintitle {
    padding-left: calc(25% + 2.4rem/3*2); } }

@media screen and (min-width: 1024px) {
  .vtb-section-maintitle {
    padding-left: calc(100%/6 + 2.4rem/3*2); } }

.vtb-section-sub-title {
  position: relative;
  z-index: 2; }
  @media screen and (min-width: 1024px) {
    .vtb-section-sub-title {
      padding: 0 1.6rem; } }

@media screen and (min-width: 1024px) {
  .vtb-section-hero::after {
    content: "";
    display: block;
    grid-column: 2/3;
    grid-row: 4/5;
    padding-top: 100%;
    background: #277BB7;
    position: relative;
    transform: translate(0, -50%); } }

.vtb-section-hero img {
  position: relative;
  z-index: -1;
  margin: 2.4rem 0; }
  @media screen and (min-width: 768px) {
    .vtb-section-hero img {
      grid-row: 1/4;
      margin: 4.8rem 0 0 0; } }
  @media screen and (min-width: 1024px) {
    .vtb-section-hero img {
      margin-top: 7.2rem; } }

.vtb-section-hero-text {
  padding: 2.4rem;
  background: #353535;
  color: #fff; }
  @media screen and (min-width: 768px) {
    .vtb-section-hero-text {
      grid-column: 3/5;
      grid-row: 1; } }
  @media screen and (min-width: 768px) {
    .vtb-section-hero-text {
      grid-column: 2/4;
      position: relative;
      margin-top: 15%; } }

.vtb-section:last-of-type {
  margin-bottom: 2.4rem; }
  @media screen and (min-width: 768px) {
    .vtb-section:last-of-type {
      margin-bottom: 4.8rem; } }

@media screen and (min-width: 768px) {
  .vtb-value-list {
    margin-top: 2.4rem; } }

@media screen and (min-width: 1366px) {
  .vtb-value-list {
    margin-top: 4.8rem; } }

.vtb-value-title {
  padding: 1.6rem 0;
  color: #145F95; }

@media screen and (max-width: 767px) {
  .vtb-value-item + .vtb-value-item {
    margin-top: 4.8rem; } }

@media screen and (min-width: 768px) {
  .vtb-value-item {
    grid-column: 1/3; }
    .vtb-value-item + .vtb-value-item {
      margin-top: -20%; }
    .vtb-value-item:nth-child(even) {
      grid-column: 3/5;
      grid-row-start: 2; }
    .vtb-value-item:last-child {
      grid-row-start: 3; } }

@media screen and (min-width: 1024px) {
  .vtb-value-item + .vtb-value-item {
    margin-top: 0; }
  .vtb-value-item:nth-child(even) {
    grid-column: 5/7;
    grid-row-start: 1;
    margin-top: 40%; }
  .vtb-value-item:last-child {
    grid-column: 2/4; } }

@media screen and (min-width: 1366px) {
  .vtb-value-item:nth-child(even) {
    margin-top: 20%; } }

@media screen and (min-width: 768px) {
  .vtb-value-wrapper {
    padding-left: 1.6rem;
    padding-right: 1.6rem; } }

.vtb-value-baseline {
  margin-top: 0.8rem; }

.vtb-nav {
  position: relative;
  z-index: -1; }
  @media screen and (min-width: 768px) {
    .vtb-nav-list {
      min-height: 50vh;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-gap: 0.1rem; } }
  .vtb-nav-itm {
    font-size: 2.4rem;
    line-height: 4rem;
    font-weight: 900;
    color: #fff; }
    .vtb-nav-itm:nth-child(even) a {
      background: #353535; }
      .vtb-nav-itm:nth-child(even) a a::before {
        color: rgba(255, 255, 255, 0.05); }
    .vtb-nav-itm a {
      display: block;
      min-height: 16.8rem;
      padding: 1.6rem 2.4rem;
      background: #277BB7;
      overflow: hidden;
      position: relative;
      z-index: 2; }
      .vtb-nav-itm a::before {
        content: attr(data-text);
        white-space: nowrap;
        color: rgba(0, 0, 0, 0.07);
        text-transform: lowercase;
        line-height: 1;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        transform-origin: left bottom;
        transform: translate(2.4rem, 2.4rem) scale(7); }
    @media screen and (max-width: 767px) {
      .vtb-nav-itm + .vtb-nav-itm {
        margin-top: 2.4rem; } }
    @media screen and (min-width: 768px) {
      .vtb-nav-itm {
        position: relative; }
        .vtb-nav-itm::after {
          content: "";
          display: none;
          width: 50%;
          padding-top: 50%;
          background: #F7F7F7;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1; }
        .vtb-nav-itm a {
          min-height: none;
          height: 100%; }
        .vtb-nav-itm:first-child {
          grid-column: 1/3;
          grid-row: 1/3; }
        .vtb-nav-itm:nth-child(2) {
          grid-column: 3/5;
          grid-row: 1/2; }
          .vtb-nav-itm:nth-child(2)::after {
            display: block;
            transform: translate(100%, 100%); }
        .vtb-nav-itm:nth-child(3) {
          grid-column: 3/5;
          grid-row: 3/5; }
        .vtb-nav-itm:nth-child(4) {
          grid-column: 1/3;
          grid-row: 3/4; }
        .vtb-nav-itm:nth-child(5) {
          grid-column: 1/2;
          grid-row: 4/5; }
        .vtb-nav-itm:nth-child(6) {
          grid-column: 3/4;
          grid-row: 5/6; } }
    @media screen and (min-width: 1024px) {
      .vtb-nav-itm:nth-child(2)::after {
        top: 0;
        transform-origin: left center;
        transform: translate(200%, -150%) scale(2, 1); }
      .vtb-nav-itm:nth-child(3) {
        grid-column: 4/6;
        grid-row: 2/4; }
        .vtb-nav-itm:nth-child(3)::after {
          display: block;
          top: auto;
          bottom: 0;
          transform: translate(200%, 66.66667%); }
      .vtb-nav-itm:nth-child(4) {
        grid-column: 2/4;
        grid-row: 3/4; }
      .vtb-nav-itm:nth-child(5) {
        grid-column: 6/7;
        grid-row: 1/2; }
      .vtb-nav-itm:nth-child(6) {
        grid-column: 1/2;
        grid-row: 4/5; }
        .vtb-nav-itm:nth-child(6)::before {
          top: -50% !important; }
        .vtb-nav-itm:nth-child(6) a {
          transform: translate(0, -50%); } }
    @media screen and (min-width: 1366px) {
      .vtb-nav-itm a {
        padding-bottom: 9.6rem;
        font-size: 3.2rem;
        line-height: 4.8rem; } }

.vtb-heading-title--simple {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 300; }
  @media screen and (min-width: 768px) {
    .vtb-heading-title--simple {
      padding-top: 4.8rem;
      padding-bottom: 4.8rem; } }
  @media screen and (min-width: 1024px) {
    .vtb-heading-title--simple {
      padding-top: 7.2rem;
      padding-bottom: 7.2rem; } }
  @media screen and (min-width: 1366px) {
    .vtb-heading-title--simple {
      font-size: 2.1rem;
      padding-top: 9.6rem;
      padding-bottom: 9.6rem; } }

.vtb-heading-hero {
  max-height: 50vh;
  grid-template-rows: 1fr 1fr 1fr; }
  @media screen and (min-width: 1024px) {
    .vtb-heading-hero {
      max-height: 65vh; } }
  .vtb-heading-hero-img {
    width: 100vw;
    grid-row: 1/4;
    position: relative;
    z-index: -1; }
  .vtb-heading-hero-text {
    grid-column: 1/4;
    padding: 2.4rem;
    background: #353535;
    color: #fff;
    position: relative; }
    @media screen and (max-width: 767px) {
      .vtb-heading-hero-text {
        width: 100vw;
        justify-self: center; } }
    @media screen and (min-width: 768px) {
      .vtb-heading-hero-text {
        grid-row: 3/4;
        grid-column: 3/5;
        padding-bottom: 9.6rem;
        transform: translate(0, 50%); }
        .vtb-heading-hero-text::before {
          content: "";
          display: block;
          width: 200vw;
          height: .1rem;
          background: #ECECEC;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0); } }
    @media screen and (min-width: 1024px) {
      .vtb-heading-hero-text {
        grid-column: 4/6;
        padding-bottom: 2.4rem;
        font-size: 2.4rem;
        line-height: 3.2rem; } }

.vtb-grid {
  grid-template-rows: auto;
  display: none;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1; }
  @media screen and (max-width: 767px) {
    .vtb-grid {
      grid-template-columns: 2.4rem 1fr 2.4rem;
      grid-auto-flow: row; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .vtb-grid {
      grid-template-columns: 4.8rem 1fr 1fr 1fr 1fr 4.8rem; } }
  @media screen and (min-width: 1024px) {
    .vtb-grid {
      grid-template-columns: 4.8rem 1fr 1fr 1fr 1fr 1fr 1fr 4.8rem; } }
  .vtb-grid span {
    display: block;
    width: .1rem;
    height: 100%;
    background: #ECECEC; }
  .vtb-grid--top {
    display: grid;
    pointer-events: auto;
    z-index: 2000;
    transition: all 0.4s cubic-bezier(0.25, 0, 0, 1); }
    @media screen and (max-width: 767px) {
      .vtb-grid--top {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-auto-flow: row; } }
    .vtb-grid--top span {
      width: calc(100% + .1rem);
      background: transparent;
      overflow: hidden;
      position: relative;
      transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
      transform: scale(1) !important; }
      .vtb-grid--top span::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: #ECECEC;
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: left center;
        transform: scale(0, 1);
        transition: inherit; }
    @media screen and (max-width: 767px) {
      .vtb-grid--top span:nth-child(n+7) {
        display: none; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .vtb-grid {
      display: grid; }
      .vtb-grid span:nth-child(n+7) {
        display: none; }
      .vtb-grid span:nth-child(6) {
        transform: translate(-100%, 0); } }
  @media screen and (min-width: 1024px) {
    .vtb-grid {
      display: grid; }
      .vtb-grid span {
        display: block; }
      .vtb-grid span:last-child {
        transform: translate(-100%, 0); } }

@media screen and (min-width: 768px) {
  .vtb-service {
    position: relative; }
    .vtb-service::after {
      content: "";
      display: block;
      width: 25%;
      padding-top: 25%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      transform: translate(0, -25%); }
    .vtb-service-1::after {
      display: none; }
    .vtb-service-2::after, .vtb-service-3::after {
      display: none; } }

@media screen and (min-width: 1024px) {
  .vtb-service::after {
    width: 16.66667%;
    padding-top: 16.66667%;
    right: 16.66667%; }
  .vtb-service-1::after {
    transform: translate(0, -8rem); }
  .vtb-service-1::before {
    content: "";
    display: block;
    width: 16.66667%;
    padding-top: 16.66667%;
    background: url("../img/misc/team-screen_it-anywhere.jpg") no-repeat center/cover;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    transform: translate(0, -50%); }
  .vtb-service-2::after, .vtb-service-3::after {
    display: block; }
  .vtb-service-2::after {
    top: auto;
    bottom: 25%;
    right: 0;
    background: url("../img/misc/keyboard-screen_it-anywhere.jpg") no-repeat center/cover;
    transform: translate(0, 0); }
  .vtb-service-3::after {
    top: auto;
    bottom: 0;
    right: auto;
    left: 0;
    background: url("../img/misc/notebook-screen_it-anywhere.jpg") no-repeat center/cover;
    transform: translate(0, 0); } }

.vtb-service-title {
  padding: 1.6rem 2.4rem;
  background: #353535;
  color: #fff;
  margin-top: -2.4rem;
  margin-bottom: 2.4rem; }
  @media screen and (min-width: 768px) {
    .vtb-service-title {
      padding: 4rem 4.8rem;
      margin-top: -4.8rem; }
      .vtb-service-title::before {
        top: 100%; } }
  @media screen and (min-width: 1024px) {
    .vtb-service-title {
      padding: 7.2rem 7.2rem;
      margin-top: -9.6rem; }
      .vtb-service-title::before {
        left: -25%; } }

.vtb-service-subtitle {
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    .vtb-service-subtitle {
      padding: 4.8rem 1.6rem; } }

.vtb-service-hero {
  position: relative;
  z-index: -1; }

.vtb-service-list {
  margin-top: 2.4rem; }
  @media screen and (min-width: 768px) {
    .vtb-service-list {
      padding: 0 1.6rem; } }

.vtb-service-cta {
  margin-top: 2.4rem; }
  .vtb-service-cta-title {
    margin: 2.4rem 0; }

.vtb-service-itm + .vtb-service-itm {
  margin-top: 4.8rem; }

.vtb-service-itm .title, .vtb-service-itm body.job .vtb-section h2, body.job .vtb-section .vtb-service-itm h2, .vtb-service-itm body.job .vtb-section h3, body.job .vtb-section .vtb-service-itm h3 {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem; }

.vtb-service-itm .icon {
  display: block;
  flex: 0 0 4.8rem;
  margin-right: 2.4rem; }
  @media screen and (min-width: 768px) {
    .vtb-service-itm .icon {
      position: absolute;
      transform: translate(-150%, 0); } }

.vtb-service-itm ul {
  margin-top: 1.6rem; }

.vtb-service-itm li {
  padding-left: 1.6rem;
  position: relative; }
  .vtb-service-itm li::before {
    content: "";
    display: inline-block;
    width: .8rem;
    height: .8rem;
    margin-right: -.8rem;
    border: 0.1rem solid #555;
    border-radius: 100rem;
    position: relative;
    top: 0;
    left: 0;
    transform: translate(-200%, -0.1rem); }

.vtb-service-contrats {
  display: flex;
  flex-wrap: wrap; }
  .vtb-service-contrats-elem {
    position: relative;
    overflow: hidden; }
    .vtb-service-contrats-elem a {
      display: block;
      background: #353535;
      overflow: hidden;
      color: #fff;
      transition: background 0.3s cubic-bezier(0.25, 0, 0, 1);
      position: relative;
      top: -6rem;
      padding: 3rem;
      font-weight: 600;
      font-size: 2.2rem; }
      .vtb-service-contrats-elem a span {
        display: block;
        position: relative;
        z-index: 2; }
      .vtb-service-contrats-elem a:after {
        content: attr(data-title);
        display: block;
        color: #1F1F1F;
        font-size: 16rem;
        font-weight: 700;
        transition: color 0.3s cubic-bezier(0.25, 0, 0, 1);
        position: relative;
        z-index: 1;
        line-height: 6rem;
        top: -3rem; }
      .vtb-service-contrats-elem a:hover {
        background: #277BB7; }
        .vtb-service-contrats-elem a:hover:after {
          color: #145F95; }
  @media screen and (min-width: 768px) {
    .vtb-service-contrats .grid-itm--expend {
      width: 50vw; } }

.vtb-anchors-wrapper {
  margin-top: 2.4rem; }
  @media screen and (min-width: 768px) {
    .vtb-anchors-wrapper {
      margin-top: 4.8rem; } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .vtb-anchors-list {
    grid-gap: 0.8rem; } }

.vtb-anchors-itm {
  padding: 2.4rem 1.6rem;
  background: #353535;
  text-align: center;
  color: #90c3e7; }
  @media screen and (max-width: 767px) {
    .vtb-anchors-itm + .vtb-anchors-itm {
      margin-top: 0.8rem; } }
  @media screen and (min-width: 768px) {
    .vtb-anchors-itm:first-child {
      grid-column: 1/3; }
    .vtb-anchors-itm:nth-child(2) {
      grid-column: 3/5; }
    .vtb-anchors-itm:last-child {
      grid-column: 2/4; } }
  @media screen and (min-width: 1024px) {
    .vtb-anchors-itm {
      padding: 12rem 2.4rem; }
      .vtb-anchors-itm:last-child {
        grid-column: 5/7; } }
  .vtb-anchors-itm .btn {
    margin-top: 0.8rem; }

.vtb-anchors-title {
  color: inherit; }

.vtb-job-title {
  margin-bottom: 3.2rem; }
  @media screen and (min-width: 1024px) {
    .vtb-job-title {
      padding-left: 1.6rem;
      margin-bottom: 5.6rem; } }

.vtb-job-itm + .vtb-job-itm {
  margin-top: 4.8rem; }
  @media screen and (min-width: 1024px) {
    .vtb-job-itm + .vtb-job-itm {
      margin-top: 5.6rem; } }

@media screen and (min-width: 768px) {
  .vtb-job-content {
    grid-column: 2/5;
    grid-row: 1/3;
    padding: 0 1.6rem 0 3.2rem; } }

@media screen and (min-width: 1024px) {
  .vtb-job-content {
    grid-column: 3/6; } }

@media screen and (min-width: 1366px) {
  .vtb-job-content {
    grid-column: 3/5; } }

.vtb-job-subtitle {
  margin-bottom: 0.8rem; }
  @media screen and (max-width: 767px) {
    .vtb-job-subtitle {
      font-size: 2.4rem; } }

.vtb-job-btn {
  margin-top: 1.6rem; }
  @media screen and (min-width: 768px) {
    .vtb-job-btn {
      grid-column: 2/3; } }

.vtb-job-img-text {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 900;
  color: #fff;
  padding: 1.6rem;
  position: absolute;
  bottom: 0;
  left: 0; }
  @media screen and (min-width: 1366px) {
    .vtb-job-img-text {
      font-size: 2.4rem;
      line-height: 3.2rem; } }

.vtb-job-img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
  background: #277BB7;
  opacity: .3;
  position: absolute;
  top: 0;
  left: 0; }
  @supports (mix-blend-mode: color) {
    .vtb-job-img::before {
      mix-blend-mode: color;
      opacity: 1; } }

@media screen and (max-width: 767px) {
  .vtb-job-img {
    display: none; } }

@media screen and (min-width: 768px) {
  .vtb-job-img {
    grid-column: 1/2;
    grid-row: 1/2; } }

@media screen and (min-width: 1024px) {
  .vtb-job-img {
    grid-column: 2/3; } }

.vtb-team-apply {
  padding: 2.4rem;
  padding-top: 4rem;
  margin-top: 2.4rem;
  background: url("../img/misc/blue-screen_it-anywhere.jpg") no-repeat center/cover;
  color: #fff;
  font-weight: 900;
  font-size: 2.1rem;
  line-height: 3.2rem; }
  @media screen and (min-width: 768px) {
    .vtb-team-apply {
      margin-top: 4.8rem;
      grid-column: 3/5;
      grid-row: 4; } }
  @media screen and (min-width: 1024px) {
    .vtb-team-apply {
      margin-top: 0;
      grid-column: 5/7; } }
  @media screen and (min-width: 1366px) {
    .vtb-team-apply {
      padding-top: 9.6rem;
      grid-column: 5/6; } }

.vtb-team-list {
  margin-top: 2.4rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none;
  grid-auto-rows: 1fr;
  grid-auto-flow: row dense; }
  .vtb-team-list:before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1; }
  .vtb-team-list > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1; }
  @media screen and (min-width: 768px) {
    .vtb-team-list {
      margin-top: 4.8rem;
      grid-gap: .1rem;
      grid-template-columns: repeat(4, 1fr); } }
  @media screen and (min-width: 1024px) {
    .vtb-team-list {
      grid-template-columns: repeat(6, 1fr); } }

.vtb-team-img {
  width: 100%;
  height: 100%;
  background-clip: border-box;
  filter: grayscale(100%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; }

.vtb-team-itm {
  grid-column: 1;
  position: relative;
  z-index: 2;
  text-align: left;
  color: #fff;
  /*@include after-in(sm){

      &:first-child{

        grid-column: 1/3;
        grid-row: 1/3;
      }
      &:nth-child(2){

        grid-column: 3/5;
        grid-row: 2/4;
      }
      &:nth-child(3){

        grid-column: 1/3;
        grid-row: 4/6;

        &::before{

          display: block;
          transform-origin: left bottom;
          transform: translate(0,-100%) scale(.5);
        }
      }
      &:nth-child(4){

        grid-column: 3/4;
        grid-row: 1;
      }
      &:last-child{

        grid-column: 4/5;
        grid-row: 4;

        &::before{

          display: block;
          transform: translate(0,100%);
        }
      }
    }

    @include after-in(md){

      &:first-child{

        grid-column: 1/3;
        grid-row: 1/3;
        &::before{

          display: block;
          transform-origin: left bottom;
          transform: translate(0,50%) scale(.5);
        }
      }
      &:nth-child(2){

        grid-column: 5/7;
        grid-row: 1/3;
      }
      &:nth-child(3){

        grid-column: 3/5;
        grid-row: 2/4;
      }
      &:nth-child(4){

        grid-column: 4/5;
        grid-row: 1;
      }
      &:last-child{

        grid-column: 4/5;
        grid-row: 4;

        &::before{

          transform: translate(200%,0);
        }
      }
    }*/ }
  .vtb-team-itm::before {
    content: "";
    display: none;
    width: 100%;
    height: 100%;
    background: #F7F7F7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
  @media screen and (min-width: 768px) {
    .vtb-team-itm {
      grid-column: auto; }
      .vtb-team-itm.wide {
        grid-column: auto / span 2;
        grid-row: auto / span 2; }
      .vtb-team-itm.offset-top {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-row: auto / span 2; }
        .vtb-team-itm.offset-top:after {
          content: ' ';
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          order: 1; }
        .vtb-team-itm.offset-top .vtb-team-wrapper {
          position: relative;
          order: 2; }
        .vtb-team-itm.offset-top.wide {
          grid-template-rows: 1fr 2fr;
          grid-row: auto / span 3; } }
  @media screen and (max-width: 767px) {
    .vtb-team-itm + .vtb-team-itm {
      margin-top: .1rem; } }
  .vtb-team-itm.min .title, .vtb-team-itm.min body.job .vtb-section h2, body.job .vtb-section .vtb-team-itm.min h2, .vtb-team-itm.min body.job .vtb-section h3, body.job .vtb-section .vtb-team-itm.min h3 {
    font-size: 2.1rem;
    line-height: 2.4rem; }
    @media screen and (min-width: 1366px) {
      .vtb-team-itm.min .title, .vtb-team-itm.min body.job .vtb-section h2, body.job .vtb-section .vtb-team-itm.min h2, .vtb-team-itm.min body.job .vtb-section h3, body.job .vtb-section .vtb-team-itm.min h3 {
        font-size: 2.4rem;
        line-height: 3.2rem; } }
  .vtb-team-itm.min p {
    font-size: 1.4rem;
    line-height: 1; }
    @media screen and (min-width: 1366px) {
      .vtb-team-itm.min p {
        font-size: 1.8rem;
        line-height: 2.4rem; } }
  .vtb-team-itm .title, .vtb-team-itm body.job .vtb-section h2, body.job .vtb-section .vtb-team-itm h2, .vtb-team-itm body.job .vtb-section h3, body.job .vtb-section .vtb-team-itm h3 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: #fff; }
    @media screen and (min-width: 1366px) {
      .vtb-team-itm .title, .vtb-team-itm body.job .vtb-section h2, body.job .vtb-section .vtb-team-itm h2, .vtb-team-itm body.job .vtb-section h3, body.job .vtb-section .vtb-team-itm h3 {
        font-size: 3.2rem;
        line-height: 4rem; } }
  .vtb-team-itm p {
    font-weight: 700; }
  .vtb-team-itm .title, .vtb-team-itm body.job .vtb-section h2, body.job .vtb-section .vtb-team-itm h2, .vtb-team-itm body.job .vtb-section h3, body.job .vtb-section .vtb-team-itm h3, .vtb-team-itm p {
    position: relative;
    z-index: 3;
    overflow: hidden; }
    .vtb-team-itm .title span, .vtb-team-itm body.job .vtb-section h2 span, body.job .vtb-section .vtb-team-itm h2 span, .vtb-team-itm body.job .vtb-section h3 span, body.job .vtb-section .vtb-team-itm h3 span, .vtb-team-itm p span {
      display: inline-block;
      transition: all 0.4s cubic-bezier(0.25, 0, 0, 1); }
      @media screen and (min-width: 1025px) {
        .vtb-team-itm .title span, .vtb-team-itm body.job .vtb-section h2 span, body.job .vtb-section .vtb-team-itm h2 span, .vtb-team-itm body.job .vtb-section h3 span, body.job .vtb-section .vtb-team-itm h3 span, .vtb-team-itm p span {
          transform: translate(0, 100%); } }
  .vtb-team-itm .vtb-team-wrapper {
    width: 100%;
    height: 100%;
    padding: 2.4rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2; }
    .vtb-team-itm .vtb-team-wrapper::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(39, 123, 183, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.32s cubic-bezier(0.25, 0, 0, 1); }
      @media screen and (min-width: 1025px) {
        .vtb-team-itm .vtb-team-wrapper::after {
          transform-origin: left bottom;
          transform: scale(0, 1); } }
    @media screen and (min-width: 1025px) {
      .vtb-team-itm .vtb-team-wrapper:hover span {
        transform: translate(0, 0); }
      .vtb-team-itm .vtb-team-wrapper:hover::after {
        transform: scale(1); } }

.vtb-partners-list {
  margin-bottom: -0.8rem;
  margin-left: -1.6rem;
  margin-right: -1.6rem; }

.vtb-partners-itm {
  padding: 0.8rem 1.6rem; }

.vtb-partners-img {
  background-color: transparent !important;
  background-size: 300% !important;
  background-position: 0 0 !important; }
  .vtb-partners-img::after {
    content: "";
    display: block;
    width: 100%;
    padding-top: 78.125%; }
  .vtb-partners-img--dellemc {
    background-position: 50% 0 !important; }
  .vtb-partners-img--veeam {
    background-position: 100% 0 !important; }
  .vtb-partners-img--microsoft {
    background-position: 0 100% !important; }
  .vtb-partners-img--hewlett {
    background-position: 50% 100% !important; }
  .vtb-partners-img--dell {
    background-position: 100% 100% !important; }

.vtb-customers-list {
  display: flex;
  margin-top: 2.4rem;
  margin-left: -2.4rem;
  margin-right: -2.4rem; }
  @media screen and (min-width: 1024px) {
    .vtb-customers-list {
      margin-left: -4.8rem;
      margin-right: -4.8rem; } }
  @media screen and (min-width: 1366px) {
    .vtb-customers-list {
      margin-left: -7.2rem;
      margin-right: -7.2rem; } }

.vtb-customers-itm {
  padding: 0 2.4rem;
  transition: all 0.64s cubic-bezier(0.25, 0, 0, 1); }
  @media screen and (min-width: 1024px) {
    .vtb-customers-itm {
      padding: 0 4.8rem; } }
  @media screen and (min-width: 1366px) {
    .vtb-customers-itm {
      flex: 0 0 20%;
      padding: 0 7.2rem; } }
  .vtb-customers-itm .ratio {
    background-size: contain !important;
    background-color: transparent !important; }

.vtb-news-list {
  grid-column: 1/6; }
  @media screen and (min-width: 1024px) {
    .vtb-news-list {
      grid-column: 2/6; } }

.vtb-news-itm {
  position: relative; }
  .vtb-news-itm + .vtb-news-itm {
    margin-top: 2.4rem; }
    @media screen and (min-width: 768px) {
      .vtb-news-itm + .vtb-news-itm {
        margin-top: 4.8rem; } }
    @media screen and (min-width: 1024px) {
      .vtb-news-itm + .vtb-news-itm {
        margin-top: 9.6rem; } }
  @media screen and (max-width: 767px) {
    .vtb-news-itm::after {
      padding-top: 100%; } }

.vtb-news-article {
  background: #277BB7;
  color: #fff; }
  .vtb-news-article-wrapper {
    padding: 1.6rem; }
    @media screen and (min-width: 768px) {
      .vtb-news-article-wrapper {
        padding: 2.4rem; } }
  .vtb-news-article-img {
    position: relative; }
    @media screen and (max-width: 640px) {
      .vtb-news-article-img {
        order: -1; } }
    .vtb-news-article-img::after {
      content: "";
      display: block;
      width: 100%;
      padding-top: 100%; }
  .vtb-news-article .title, .vtb-news-article body.job .vtb-section h2, body.job .vtb-section .vtb-news-article h2, .vtb-news-article body.job .vtb-section h3, body.job .vtb-section .vtb-news-article h3 {
    color: #fff; }
  @media screen and (min-width: 768px) and (max-width: 1365px) {
    .vtb-news-article p {
      font-size: 1.5rem;
      line-height: 2.1rem; } }
  @media screen and (max-width: 640px) {
    .vtb-news-article p {
      font-size: 1.4rem; } }
  .vtb-news-article .btn {
    margin-top: 0.8rem; }
    @media screen and (max-width: 768px) {
      .vtb-news-article .btn {
        width: 100%; } }

.vtb-pagination-wrapper {
  grid-column: 1/6;
  border-top: 0.1rem solid #aaa;
  margin-top: 4rem; }
  @media screen and (min-width: 1024px) {
    .vtb-pagination-wrapper {
      margin-top: 4.8rem;
      grid-column: 2/6; } }

.vtb-pagination-list {
  transform: translate(-0.8rem, 0); }

.vtb-pagination-itm {
  position: relative; }
  .vtb-pagination-itm + .vtb-pagination-itm::after {
    content: "";
    display: block;
    width: .4rem;
    height: .1rem;
    background: #aaa;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, 0); }
  .vtb-pagination-itm a {
    display: block;
    padding: 2.4rem 0.8rem; }
    .vtb-pagination-itm a.active {
      font-weight: bold;
      color: #277BB7; }

.vtb-article-header, .vtb-article-content, .vtb-article-footer, .vtb-article-others {
  grid-column: 1/6; }
  @media screen and (min-width: 1024px) {
    .vtb-article-header, .vtb-article-content, .vtb-article-footer, .vtb-article-others {
      grid-column: 2/6; } }

.vtb-article-header {
  padding: 0 1.6rem;
  margin-top: 2.4rem; }
  @media screen and (min-width: 1024px) {
    .vtb-article-header {
      margin-top: 4.8rem; } }
  .vtb-article-header-img {
    margin-top: 1.6rem;
    margin-left: -1.6rem;
    margin-right: -1.6rem; }

.vtb-article-content .title + *, .vtb-article-content body.job .vtb-section h2 + *, body.job .vtb-section .vtb-article-content h2 + *, .vtb-article-content body.job .vtb-section h3 + *, body.job .vtb-section .vtb-article-content h3 + * {
  margin-top: 2.4rem; }

.vtb-article-content a {
  color: #277BB7;
  border-bottom: 0.1rem solid #277BB7; }

.vtb-article-content ul {
  padding-left: 1.6rem;
  margin: 0.8rem 0; }
  .vtb-article-content ul li {
    position: relative; }
    .vtb-article-content ul li::before {
      content: "";
      display: block;
      width: .4rem;
      height: .4rem;
      background: #277BB7;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-1.6rem, 1.5rem); }

.vtb-article-section {
  margin-top: 2.4rem; }
  @media screen and (min-width: 1024px) {
    .vtb-article-section {
      margin-top: 4.8rem; } }
  @media screen and (min-width: 1366px) {
    .vtb-article-section {
      margin-top: 5.6rem; } }
  .vtb-article-section--text {
    padding: 0 1.6rem; }

.vtb-article-footer-btn {
  margin-top: 2.4rem; }
  @media screen and (min-width: 1024px) {
    .vtb-article-footer-btn {
      margin-top: 4.8rem; } }

.vtb-article-infos {
  padding: 1.2rem 0;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  position: relative; }
  .vtb-article-infos::after, .vtb-article-infos::before {
    content: '';
    display: block;
    width: 100%;
    height: .1rem;
    padding: 0 1.2rem;
    background: #aaa;
    background-clip: content-box;
    position: absolute;
    left: 0; }
  .vtb-article-infos::after {
    top: 0; }
  .vtb-article-infos::before {
    bottom: 0; }
  .vtb-article-infos > div {
    margin: 1.2rem; }
  .vtb-article-infos .title, .vtb-article-infos body.job .vtb-section h2, body.job .vtb-section .vtb-article-infos h2, .vtb-article-infos body.job .vtb-section h3, body.job .vtb-section .vtb-article-infos h3 {
    margin-right: 2.4rem;
    color: #000; }

.vtb-article-tags-itm {
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin-right: 0.8rem; }
  .vtb-article-tags-itm a {
    border-bottom: 0.1rem solid #aaa; }
  .vtb-article-tags-itm:last-child {
    margin-right: 0; }

.vtb-article-sharing-list {
  margin-left: 2.4rem; }

.vtb-article-sharing-itm + .vtb-article-sharing-itm {
  margin-left: 0.8rem; }

.vtb-article-sharing-itm button {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  background: #353535;
  cursor: pointer;
  font-size: 1.4rem;
  color: #fff;
  position: relative; }

.vtb-article-sharing-itm i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.vtb-article-others-title {
  margin: 2.4rem 0 0.8rem 0; }

.vtb-article-others-list {
  display: grid;
  grid-gap: .1rem;
  grid-template-columns: auto;
  grid-auto-flow: row; }
  @media screen and (min-width: 768px) {
    .vtb-article-others-list {
      grid-template-columns: auto auto; } }

.vtb-article-others-itm {
  position: relative; }
  .vtb-article-others-itm article {
    width: 100%;
    height: 100%;
    padding: 1.6rem;
    background: rgba(39, 123, 183, 0.8);
    position: absolute;
    top: 0;
    left: 0; }
    @media screen and (min-width: 768px) {
      .vtb-article-others-itm article {
        padding: 2.4rem; } }
  .vtb-article-others-itm .title, .vtb-article-others-itm body.job .vtb-section h2, body.job .vtb-section .vtb-article-others-itm h2, .vtb-article-others-itm body.job .vtb-section h3, body.job .vtb-section .vtb-article-others-itm h3 {
    color: #fff;
    margin-bottom: 0.8rem; }

.vtb-form {
  position: relative;
  grid-column: 1; }
  @media screen and (min-width: 768px) {
    .vtb-form {
      grid-column: 3/5; } }
  @media screen and (min-width: 1024px) {
    .vtb-form {
      grid-column: 4/6; } }
  @media screen and (max-width: 767px) {
    .vtb-form {
      margin-top: 2.4rem; } }
  .vtb-form-divider {
    position: relative; }
    .vtb-form-divider::after {
      content: "";
      display: block;
      width: 100%;
      height: .1rem;
      background: #aaa;
      position: absolute;
      bottom: 0;
      left: 0; }
    .vtb-form-divider--submit::after {
      display: none; }
    .vtb-form-divider + .vtb-form-divider {
      margin-top: 1.6rem; }
  .vtb-form-input:focus + label {
    color: #277BB7;
    transform: scale(0.75) translate(0.4rem, -75%); }
  .vtb-form-input--submit {
    flex: 0 0 100%; }
  .vtb-form-input--honeypot {
    display: none; }
  .vtb-form-label {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left bottom;
    transition: all 0.4s cubic-bezier(0.25, 0, 0, 1); }
  .vtb-form-message {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    padding: 0.8rem 0;
    background: #353535;
    opacity: 0;
    visibility: hidden;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 20;
    transform-origin: center;
    transform: translate(0, -50%) scale(0.98);
    transition: all 0.4s cubic-bezier(0.25, 0, 0, 1); }
    .vtb-form-message.show {
      opacity: 1;
      visibility: visible;
      transform: translate(0, -50%) scale(1); }
    .vtb-form-message p {
      padding: 0.8rem 2.4rem; }

body.history .vtb-history {
  position: relative; }
  @media screen and (min-width: 768px) {
    body.history .vtb-history-title {
      margin-bottom: 7.2rem;
      padding-left: calc(25% + 2.4rem/3*2); } }
  @media screen and (min-width: 1024px) {
    body.history .vtb-history-title {
      padding-left: calc(100%/6 + 2.4rem/3*2);
      margin-bottom: 4.8rem; } }
  @media screen and (min-width: 1366px) {
    body.history .vtb-history-title {
      margin-bottom: 9.6rem; } }
  body.history .vtb-history-text1 {
    position: relative; }
    @media screen and (min-width: 768px) {
      body.history .vtb-history-text1 {
        grid-row-start: 2;
        grid-row-end: 4;
        grid-column: 1/4; }
        body.history .vtb-history-text1 p {
          padding: 0 1.6rem; } }
    @media screen and (min-width: 1024px) {
      body.history .vtb-history-text1 {
        grid-column: 1/4; } }
    @media screen and (min-width: 1366px) {
      body.history .vtb-history-text1 {
        grid-column: 2/4; } }
  body.history .vtb-history-img1 {
    margin-bottom: 3.2rem;
    grid-row-start: 2;
    grid-row-end: 3; }
    @media screen and (min-width: 768px) {
      body.history .vtb-history-img1 {
        grid-column: 4/5; } }
    @media screen and (min-width: 1024px) {
      body.history .vtb-history-img1 {
        grid-column: 5/7;
        position: relative;
        margin-bottom: 50%; }
        body.history .vtb-history-img1::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: url("../img/misc/blue-screen_it-anywhere.jpg") no-repeat center/cover;
          position: absolute;
          top: 100%;
          left: 0;
          transform-origin: left top;
          transform: scale(0.5) translate(-100%, 0); } }
  body.history .vtb-history-hero {
    margin: 3.2rem 0;
    position: relative;
    z-index: -1; }
    @media screen and (min-width: 768px) {
      body.history .vtb-history-hero {
        margin: 4.8rem 0; } }
    @media screen and (min-width: 1024px) {
      body.history .vtb-history-hero {
        margin-top: 9.6rem; } }
  @media screen and (min-width: 768px) {
    body.history .vtb-history-text2 {
      grid-column: 2/5;
      grid-row: 5/7;
      padding: 0 1.6rem; } }
  @media screen and (min-width: 1024px) {
    body.history .vtb-history-text2 {
      grid-column: 4/7;
      position: relative; }
      body.history .vtb-history-text2::before {
        content: "";
        display: block;
        width: 33.33333%;
        padding-top: 33.33333%;
        background: #F7F7F7;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transform-origin: left top;
        transform: translate(-100%, 100%); } }
  @media screen and (min-width: 1366px) {
    body.history .vtb-history-text2 {
      grid-column: 4/6;
      align-self: center;
      margin-top: -50%; }
      body.history .vtb-history-text2::before {
        width: 50%;
        padding-top: 50%; } }
  body.history .vtb-history-img3 {
    margin-top: 3.2rem; }
    @media screen and (min-width: 768px) {
      body.history .vtb-history-img3 {
        margin-top: 0;
        grid-column: 1/2;
        grid-row-start: 5; } }
    @media screen and (min-width: 1024px) {
      body.history .vtb-history-img3 {
        grid-column: 1/3;
        margin-bottom: 50%; }
        body.history .vtb-history-img3::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: #F7F7F7;
          position: absolute;
          top: 100%;
          left: 0;
          z-index: -1;
          transform-origin: left top;
          transform: scale(0.5) translate(0, 0); } }

body.career .vtb-section {
  position: relative; }
  @media screen and (min-width: 1024px) {
    body.career .vtb-section + .vtb-section {
      margin-top: 0 !important; } }
  body.career .vtb-section-img {
    margin-top: 2.4rem; }
    @media screen and (min-width: 768px) {
      body.career .vtb-section-img {
        grid-column: 4/5;
        grid-row: 2/3;
        margin-top: 0;
        position: relative; } }
    @media screen and (min-width: 1024px) {
      body.career .vtb-section-img {
        grid-column: 5/7;
        position: relative; } }
  @media screen and (min-width: 768px) {
    body.career .vtb-section-sub-title {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      grid-column: 1/4;
      grid-row: 2/4; } }
  @media screen and (min-width: 1024px) {
    body.career .vtb-section-sub-title {
      grid-column: 1/4; } }
  @media screen and (min-width: 1366px) {
    body.career .vtb-section-sub-title {
      grid-column: 2/4; } }

@media screen and (min-width: 768px) {
  body.team .vtb-section-sub-title {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    grid-column: 1/4;
    grid-row: 2/4; } }

@media screen and (min-width: 1024px) {
  body.team .vtb-section-sub-title {
    grid-column: 2/5; } }

@media screen and (min-width: 1366px) {
  body.team .vtb-section-sub-title {
    grid-column: 2/4; } }

@media screen and (min-width: 1024px) {
  body.contact .vtb-section {
    margin-bottom: 0; } }

@media screen and (min-width: 768px) {
  body.contact .vtb-section-sub-title {
    grid-column: 1/3; } }

@media screen and (min-width: 1024px) {
  body.contact .vtb-section-sub-title {
    grid-column: 2/4; } }

body.contact .vtb-section-hero::after {
  display: none; }

body.contact .vtb-section-hero-text {
  grid-column: 1/3; }
  @media screen and (min-width: 1024px) {
    body.contact .vtb-section-hero-text {
      grid-column: 2/4; } }

.flex-column {
  display: flex;
  flex-direction: column; }
  .flex-column .space-grow {
    flex-grow: 1; }
  .flex-column .btn {
    margin-top: 3rem; }
    @media screen and (min-width: 1024px) {
      .flex-column .btn {
        margin-left: -1.6rem; } }

body.job .vtb-job {
  position: relative; }
  @media screen and (min-width: 768px) {
    body.job .vtb-job-title {
      margin-bottom: 7.2rem;
      padding-left: calc(25% + 2.4rem/3*2); } }
  @media screen and (min-width: 1024px) {
    body.job .vtb-job-title {
      padding-left: calc(100%/6 + 2.4rem/3*2);
      margin-bottom: 4.8rem; } }
  @media screen and (min-width: 1366px) {
    body.job .vtb-job-title {
      margin-bottom: 9.6rem; } }

body.job .vtb-section {
  position: relative; }
  @media screen and (min-width: 768px) {
    body.job .vtb-section {
      padding: 1.6rem; } }
  body.job .vtb-section h2 {
    padding: 3.2rem 0 1.6rem; }
    body.job .vtb-section h2:first-child {
      padding-top: 0; }
  body.job .vtb-section h3 {
    margin-top: 0.5em;
    font-size: 130%; }
  body.job .vtb-section a {
    color: #277BB7;
    text-decoration: underline;
    transition: 0.2s color cubic-bezier(0.47, 0, 0.745, 0.715); }
    body.job .vtb-section a:hover, body.job .vtb-section a:focus {
      color: #66acde; }
  body.job .vtb-section ul {
    margin: 0.5em 0 0.5em 1em; }
    body.job .vtb-section ul li {
      list-style: circle; }
      body.job .vtb-section ul li + li {
        margin-top: 0.5em; }
    body.job .vtb-section ul li > ul > li {
      list-style-type: disc; }
  body.job .vtb-section p + p {
    margin-top: 1.6rem; }
  @media screen and (min-width: 1024px) {
    body.job .vtb-section {
      grid-column: 2 / 5; } }
  @media screen and (min-width: 1024px) {
    body.job .vtb-section + .vtb-section {
      margin-top: 0 !important; } }

.main-header {
  width: 100vw;
  height: 7.2rem;
  padding: 1.2rem 2.4rem;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100; }
  .main-header::after {
    content: "";
    display: block;
    width: 100%;
    height: .1rem;
    background: #ECECEC;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 110; }
  @media screen and (min-width: 768px) {
    .main-header {
      padding-left: 4.8rem;
      padding-right: 4.8rem; } }
  .main-header-logo {
    height: 4.8rem;
    padding-left: 5.6rem;
    font-weight: 900;
    font-size: 2rem;
    line-height: 3.2rem;
    color: #555;
    position: relative; }
    @media screen and (max-width: 374px) {
      .main-header-logo {
        text-indent: -999.9rem; } }
    .main-header-logo span {
      display: inline-block;
      opacity: 0;
      position: absolute; }
    .main-header-logo svg {
      display: block;
      width: 4.8rem;
      height: 4.8rem;
      position: absolute;
      top: 0;
      left: 0; }
    .main-header-logo small {
      display: block;
      font-size: 1rem;
      line-height: .2rem;
      position: absolute;
      right: 0; }
  .main-header-langs {
    width: 4.8rem;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    z-index: 120;
    top: 1.2rem;
    right: 8rem;
    transition: all 0.4s cubic-bezier(0.25, 0, 0, 1); }
    @media screen and (min-width: 768px) {
      .main-header-langs {
        width: calc(100%/4 - 2.4rem);
        padding-top: 8.8rem;
        padding-bottom: 1.6rem;
        opacity: 1;
        top: 0;
        right: calc(25% + 2.4rem); }
        .main-header-langs::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: #F7F7F7;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          transform-origin: left center;
          transform: scale(0, 1);
          transition: inherit; } }
    @media screen and (min-width: 1024px) {
      .main-header-langs {
        width: calc(100%/6 - 2.4rem/3*2);
        right: calc(100%/6 + 2.4rem + 2.4rem/3); } }
  .main-header-lang {
    width: 100%;
    height: 4.8rem;
    background: #F7F7F7;
    color: #555;
    font-family: "Pathway Gothic One", "Roboto", Helvetica, Arial, sans-serif;
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;
    transition: transform 0.4s cubic-bezier(0.25, 0, 0, 1); }
    @media screen and (min-width: 768px) {
      .main-header-lang {
        height: auto;
        background: transparent;
        line-height: 3.2rem; } }
    .main-header-lang a {
      display: flex;
      height: 100%;
      justify-content: center;
      aline-items: center;
      transition: all 0.4s cubic-bezier(0.25, 0, 0, 1); }
      @media screen and (min-width: 768px) {
        .main-header-lang a {
          padding: 0 1.6rem;
          justify-content: flex-start;
          transform: translate(-100%, 0); } }
      .main-header-lang a span {
        align-self: center; }
      @media screen and (min-width: 1025px) {
        .main-header-lang a:hover {
          color: #277BB7; } }
  .main-header-menu {
    display: block;
    width: 100vw;
    height: 100vh;
    padding: inherit;
    padding-bottom: 2.4rem;
    padding-top: 9.6rem;
    background: #fff;
    opacity: 0;
    pointer-events: none;
    color: #555;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: opacity 0.4s cubic-bezier(0.25, 0, 0, 1); }
    @media screen and (min-width: 768px) {
      .main-header-menu {
        width: calc(100%/4 - 2.4rem);
        padding-top: 8.8rem;
        padding-left: 0;
        padding-right: 0;
        opacity: 1;
        background: transparent;
        color: rgba(0, 0, 0, 0.55);
        position: absolute;
        left: auto;
        right: 4.8rem; }
        .main-header-menu::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: #277BB7;
          box-shadow: 0 0 4.8rem 0 rgba(0, 0, 0, 0.15);
          position: absolute;
          top: 0;
          left: 0;
          transform-origin: left center;
          transform: scale(0, 1);
          transition: transform 0.4s cubic-bezier(0.25, 0, 0, 1); } }
    @media screen and (min-width: 1024px) {
      .main-header-menu {
        width: calc(100%/6 - 2.4rem/3*2); } }
  .main-header-links {
    height: 100%; }
    @media screen and (min-width: 768px) {
      .main-header-links {
        flex-direction: row;
        height: auto; } }
  .main-header-link {
    width: 100%;
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
    transition: all 0.4s cubic-bezier(0.25, 0, 0, 1);
    transform: translate(0, 2.4rem); }
    .main-header-link + .main-header-link {
      margin-top: 0.8rem; }
      @media screen and (min-width: 768px) {
        .main-header-link + .main-header-link {
          margin-top: 0.4rem; } }
    @media screen and (min-width: 768px) {
      .main-header-link {
        transform: translate(0, 0); } }
    .main-header-link a {
      padding: 0 4.8rem;
      width: 100%;
      height: 100%;
      font-size: 2.4rem;
      line-height: 1;
      font-weight: 900;
      text-transform: lowercase;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      transform: translate(0, 100%);
      transition: all 0.4s cubic-bezier(0.25, 0, 0, 1); }
      .main-header-link a::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: #ECECEC;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: inherit; }
      .main-header-link a.active {
        color: #fff; }
        .main-header-link a.active::before {
          background: #277BB7; }
      @media screen and (min-width: 768px) {
        .main-header-link a {
          height: auto;
          padding: 0 1.6rem;
          opacity: 0;
          font-family: "Pathway Gothic One", "Roboto", Helvetica, Arial, sans-serif;
          font-weight: 400;
          line-height: 3.2rem;
          text-transform: uppercase;
          position: relative;
          transform: translate(-100%, 0); }
          .main-header-link a::before {
            display: none; } }
      @media screen and (min-width: 1025px) {
        .main-header-link a:hover {
          color: #fff;
          transition-delay: 0s !important; } }
    @media screen and (max-width: 767px) {
      .main-header-link span::before {
        content: attr(data-text);
        dispay: block;
        color: rgba(0, 0, 0, 0.05);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        transform-origin: left bottom;
        transform: scale(3.4) translate(0, 0.25rem); } }
  .main-header.menu-open .main-header-menu {
    opacity: 1;
    pointer-events: auto; }
    .main-header.menu-open .main-header-menu::before {
      transform: scale(1, 1); }
  .main-header.menu-open .main-header-link {
    transform: translate(0, 0); }
    .main-header.menu-open .main-header-link a {
      opacity: 1;
      transform: translate(0, 0);
      transition-delay: inherit; }
  .main-header.langs-open .main-header-langs {
    pointer-events: auto;
    opacity: 1; }
    @media screen and (max-width: 767px) {
      .main-header.langs-open .main-header-langs {
        transform: translate(0, 5.6rem); } }
    .main-header.langs-open .main-header-langs::before {
      transform: scale(1, 1); }
    .main-header.langs-open .main-header-langs a {
      transform: translate(0, 0); }

.main-footer {
  padding: 2.4rem 0 0 0;
  background: #353535;
  color: #fff;
  font-weight: 400;
  font-size: 1.6rem;
  position: relative; }
  @media screen and (min-width: 1024px) {
    .main-footer {
      font-size: 1.4rem;
      line-height: 2.4rem; } }
  @media screen and (min-width: 1366px) {
    .main-footer {
      font-size: 1.6rem; } }
  .main-footer-icon {
    padding-left: 2.4rem;
    position: relative; }
    .main-footer-icon::before {
      content: "";
      display: inline-block;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -60%); }
    .main-footer-icon--in::before {
      background: url("../img/icons/linkedin_it-anywhere.svg") no-repeat center/cover; }
    .main-footer-icon--tw::before {
      background: url("../img/icons/twitter_it-anywhere.svg") no-repeat center/cover; }
  .main-footer, .main-footer-legal {
    position: relative;
    z-index: 2; }
    .main-footer::before, .main-footer-legal::before {
      content: "";
      display: block;
      width: 100vw;
      height: 100%;
      background: inherit;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, 0); }
    @media screen and (max-width: 767px) {
      .main-footer, .main-footer-legal {
        grid-column: 1/4; } }
  @media screen and (min-width: 768px) {
    .main-footer-adress {
      grid-column: 1/3; } }
  @media screen and (min-width: 1024px) {
    .main-footer-adress {
      grid-column: 1/4; } }
  @media screen and (max-width: 767px) {
    .main-footer-partners {
      margin-top: 2.4rem; } }
  @media screen and (min-width: 768px) {
    .main-footer-partners {
      grid-column: 3/5; } }
  @media screen and (min-width: 1024px) {
    .main-footer-partners {
      grid-column: 5/7; } }
  .main-footer .partners-logos {
    align-items: center;
    margin: calc(1.6rem - 2.4rem) -2.4rem 0; }
    .main-footer .partners-logos p {
      padding: 2.4rem; }
    .main-footer .partners-logos p + p {
      margin-top: 0; }
  .main-footer .partner-logo {
    height: auto;
    width: auto;
    max-width: 80%;
    max-height: 80%; }
  .main-footer-title {
    color: #277BB7;
    font-weight: 900;
    font-size: 1.8rem; }
  .main-footer-legal {
    padding: 2.4rem 0;
    margin-top: 2.4rem;
    background: #1F1F1F; }
    .main-footer-legal   {
      grid-column: 1/5; }
    @media screen and (min-width: 1024px) {
      .main-footer-legal {
        grid-column: 1/7; } }
  .main-footer small {
    font-size: 1.4rem;
    line-height: 2.4rem; }
