&-customers{

  &-list{

    display: flex;
    margin-top: $gut;

    margin-left: -$gut;
    margin-right: -$gut;

    @include after-in(md){

      margin-left: -$gut*2;
      margin-right: -$gut*2;
    }

    @include after-in(lg){

      margin-left: -$gut*3;
      margin-right: -$gut*3;
    }
  }

  &-itm{

    padding: 0 $gut;

    @include after-in(md){

      padding: 0 $gut*2;
    }

    @include after-in(lg){

      flex: 0 0 $full/5;
      padding: 0 $gut*3;
    }

    transition: all .64s $authenticMotion;

    .ratio{

      background-size: contain !important;
      background-color: transparent !important;
    }
  }
}
