body.js-active {

  .js-shell {

    opacity: 1;
    visibility: visible;
    transform-origin: top;
    transition: all .8s $easeInOutMoovia;

    &.hide-outgoing {

      opacity: 0;
      visibility: hidden;
    }

    &.hide-coming {

      opacity: 0;
      visibility: hidden;
      // transform: scale(1.1);
    }
  }

  body.fade {

    height: 100vh !important;
    overflow: hidden !important;

    &, * {

      transition-duration: .8s !important;
      transition-property: all !important;
      transition-timing-function: $easeInOutMoovia !important;
    }
  }
}

.js-curtain {

  transition-duration: 6 * .03 + .4 + s !important;

  @include after-in(md) {

    transition-duration: 8 * .03 + .4 + s !important;
  }

  @for $i from 1 through 8 {

    span:nth-child(#{$i}) {

      transition-delay: $i * .03 + s;
    }
  }

  &.show {

    span::after {

      // background: color(black);
      transform: scale(1,1) !important;
    }
  }

  &.hide {

    span::after {

      // background: color(light);
      transform: translate(100%,0) scale(1) !important;
      transition-timing-function: ease;
    }
  }

  // width: 100vw;
  // height: 100vh;
  //
  // background: red;
  //
  // transition: all .48s ease;
  //
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 200;
  //
  // transition: all .56s $easeInOutQuart;
  //
  // &::before {
  //
  //   content: "";
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //
  //   background: color(second);
  //
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   transform: translate3d(0,100%,0);
  //   will-change: transform;
  //
  //   transition: transform .48s $easeInOutQuart;
  // }
  //
  // &::after {
  //
  //   content: "";
  //   display: block;
  //   width: 6vw;
  //   height: 6vw;
  //
  //   background: url($img + "icons/ville-de-wavre-benchmark" + $suffix+$svg) no-repeat center/contain;
  //   opacity: 0;
  //
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate3d(-50%,100%,0);
  //   will-change: transform, opacity;
  //
  //   transition: all .48s $easeInOutQuart .08s;
  //
  //   @include before-in(sm) {
  //
  //     width: 10vw;
  //     height: 10vw;
  //   }
  //
  //   @include before-in(xxs) {
  //
  //     width: 20vw;
  //     height: 20vw;
  //   }
  // }
  //
  // &.show {
  //
  //   &::before {
  //
  //     transform: translate3d(0,0,0);
  //   }
  //
  //   &::after {
  //
  //     opacity: 1;
  //     transform: translate3d(-50%,-50%,0);
  //   }
  // }
  //
  // &.hide {
  //
  //   &::before {
  //
  //     transform: translate3d(0,-100%,0);
  //     transition-delay: .08s;
  //   }
  //   &::after {
  //
  //     opacity: 0;
  //     transform: translate3d(-50%,-200%,0);
  //     transition-delay: 0;
  //   }
  // }
}
