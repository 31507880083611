&-nav {
    position: relative;
    z-index: -1;

    &-list {
        @include after-in(sm) {
            min-height: 50vh;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            grid-gap: 0.1rem;
        }
    }

    &-itm {
        font-size: 2.4rem;
        line-height: 4rem;
        font-weight: 900;
        color: color(white);

        &:nth-child(even) a {
            background: color(dark);

            a::before {
                color: rgba(white,.05);
            }
        }

        a {
            display: block;
            min-height: $gut*7;
            padding: $gut/3*2 $gut;
            background: color(second);
            overflow: hidden;
            position: relative;
            z-index: 2;

            &::before {
                content: attr(data-text);
                white-space: nowrap;
                color: rgba(black,.07);
                text-transform: lowercase;
                line-height: 1;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: -1;
                transform-origin: left bottom;
                transform: translate($gut,$gut) scale(7);
            }
        }
        @include before-out(sm) {
            & + & {
                margin-top: $gut;
            }
        }
        @include after-in(sm) {
            position: relative;

            &::after {
                content: "";
                display: none;
                width: 50%;
                padding-top: 50%;
                background: color(lighter);
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }

            a {
                min-height: none;
                height: 100%;
            }

            &:first-child {
                grid-column: 1/3;
                grid-row: 1/3;
            }

            &:nth-child(2) {
                grid-column: 3/5;
                grid-row: 1/2;

                &::after {
                    display: block;
                    transform: translate(100%,100%);
                }
            }

            &:nth-child(3) {
                grid-column: 3/5;
                grid-row: 3/5;
            }

            &:nth-child(4) {
                grid-column: 1/3;
                grid-row: 3/4;
            }

            &:nth-child(5) {
                grid-column: 1/2;
                grid-row: 4/5;
            }

            &:nth-child(6) {
                grid-column: 3/4;
                grid-row: 5/6;
            }
        }
        @include after-in(md) {
            &:nth-child(2) {
                &::after {
                    top: 0;
                    transform-origin: left center;
                    transform: translate(200%,-$full*1.5) scale(2,1);
                }
            }

            &:nth-child(3) {
                grid-column: 4/6;
                grid-row: 2/4;

                &::after {
                    display: block;
                    top: auto;
                    bottom: 0;
                    transform: translate(200%,$full/3*2);
                }
            }

            &:nth-child(4) {
                grid-column: 2/4;
                grid-row: 3/4;

                // &::after {
                //     display: block;
                //     transform-origin: left center;
                //     top: auto;
                //     bottom: 0;
                //     transform: translate(-100%,$full/3*2) scale(1);
                //     z-index: 2;
                // }
            }

            &:nth-child(5) {
                grid-column: 6/7;
                grid-row: 1/2;
            }

            &:nth-child(6) {
                // display: none;
                grid-column: 1/2;
                grid-row: 4/5;

                &::before{

                    top: -50% !important;
                }

                a{

                    transform: translate(0,-50%);
                }
            }
        }
        @include after-in(lg) {
            a {
                padding-bottom: $gut*4;
                font-size: 3.2rem;
                line-height: 4.8rem;
            }
        }
    }
}
