body.js-active{

  *.js-animitem{

    &[data-trans="fade"]{

      opacity: 0;
    }

    &[data-trans="fade--bottom"]{

      opacity: 0;
      transform: translate3d(0,$gut*6,0)
    }

    &[data-trans="curtain--bottom"]{

      position: relative;
      opacity: 0;
      transform-origin: center bottom;
      transform: translate3d(0,$gut*6,0);

      &::before{

        content: "";
        display: block;
        width: 100%;
        height: 100%;

        // background: color(light);
        background: color(white);

        position: absolute;
        top: 0; left: 0;
        z-index: 3;

        transform: scale(1);
        transition-duration: .48s;
        transition-delay: inherit;
        transition-timing-function: $authenticMotion;
      }
    }

    &[data-trans="fade--left"]{

      opacity: 0;
      transform: translate3d(-$gut*6,0,0);
    }
    &[data-trans="fade--right"]{

      opacity: 0;
      transform: translate3d($gut*6,0,0);
    }

    &[data-trans="fade--bottom--itms"]{

      .js-animitem-sub {

        opacity: 0;
        transform: translate3d(0,$gut*6,0);
      }
    }

    &[data-trans="fade--left--itms"]{

      .js-animitem-sub {

        opacity: 0;
        transform: translate3d(-$gut*6,0,0);
      }
    }

    &[data-trans="curtain--bottom--itms"]{

      .js-animitem-sub{

        position: relative;
        opacity: 0;
        transform-origin: center bottom;
        transform: translate3d(0,$gut*6,0);

        &::before{

          content: "";
          display: block;
          width: 100%;
          height: 100%;

          // background: color(light);
          background: color(white);

          position: absolute;
          top: 0; left: 0;
          z-index: 3;

          transform: scale(1);
          transition-duration: .48s;
          transition-delay: inherit;
          transition-timing-function: $authenticMotion;
        }
      }
    }

    &.animated{

      transition-duration: .8s;
      transition-timing-function: $authenticMotion;

      .js-animitem-sub{

        transition-duration: .8s;
        transition-timing-function: $authenticMotion;
      }

      &[data-trans="fade"]{

        transition-property: opacity;
        opacity: 1;
        transition-duration: .72s;
      }

      &[data-trans="fade--left"], &[data-trans="fade--right"]{

        transition-property: transform, opacity;
        opacity: 1;
        transform: translate3d(0,0,0);
      }

      &[data-trans="fade--bottom"]{

        opacity: 1;
        transform: translate3d(0,0,0);
        transition-property: transform, opacity;
      }

      &[data-trans="curtain--bottom"]{


        opacity: 1;
        transform: translate3d(0,0,0);
        transition-duration: .48s;

        &::before{

          transition-property: transform;
          transform-origin: right top;
          transform: scale(1,0);
        }
      }

      &[data-trans="curtain--bottom--itms"]{

        .js-animitem-sub{

          opacity: 1;
          transform: translate3d(0,0,0);
          transition-duration: .48s;

          &::before{

            transition-property: transform;
            transform-origin: right top;
            transform: scale(1,0);
          }
        }
      }

      &[data-trans="fade--bottom--itms"], &[data-trans="fade--left--itms"]{

        .js-animitem-sub{

          transition-property: transform, opacity;
          opacity: 1;
          transform: translate3d(0,0,0);
        }
      }
    }
  }
}
