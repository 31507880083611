@mixin font-smoothing {

  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
//
// @mixin clearfix {
//
//   zoom: 1;
//
//   &:before,
//   &:after {
//     content: "";
//     display: table;
//   }
//   &:after {
//     clear: both;
//   }
// }

@function color($key) {
  @return map-get($colors, $key);
}
@function tag($key) {
  @return map-get($tags, $key);
}
@function size($key) {
  @return map-get($breakpoints, $key);
}

//// ---- PROJECT MIXINS ---- \\\\
