body.history{

  .vtb{

    &-history{

      position: relative;

      &-title {

        @include after-in(sm){

          margin-bottom: $gut*3;
          padding-left: calc(25% + #{$gut}/3*2);
        }

        @include after-in(md) {

          padding-left: calc(#{$full}/6 + #{$gut}/3*2);
          margin-bottom: $gut*2;
        }

        @include after-in(lg) {

          margin-bottom: $gut*4;
        }
      }


      &-text1{

        position: relative;

        @include after-in(sm){

          grid-row-start: 2;
          grid-row-end: 4;
          // align-self: center;

          p{

            padding: 0 $gut/3*2;
          }

          grid-column: 1/4;
        }

        @include after-in(md) {

          grid-column: 1/4;
        }
        @include after-in(lg) {

          grid-column: 2/4;
        }
      }

      &-img1{

        margin-bottom: $gut/3*4;
        grid-row-start: 2;
        grid-row-end: 3;

        @include after-in(sm){

          grid-column: 4/5;
          // margin-top: $gut*3;
          // position: absolute;
          // right: 0;
          // grid-row: auto;
          // margin-top: -20%;
          // position: absolute;
        }

        @include after-in(md){

          grid-column: 5/7;

          position: relative;

          margin-bottom: 50%;

          &::before{

            content: "";
            display: block;
            width: 100%;
            height: 100%;

            background: url($img + "misc/blue-screen" + $suffix + $jpg) no-repeat center/cover;

            position: absolute;
            top: 100%;
            left: 0;

            transform-origin: left top;
            transform: scale(.5) translate(-100%,0);
          }
        }
      }

      &-hero {

        margin: $gut/3*4 0;

        position: relative;
        z-index: -1;

        @include after-in(sm){

          margin: $gut*2 0;
        }

        @include after-in(md) {

          margin-top: $gut*4;
        }
      }

      &-text2{

        @include after-in(sm){

          grid-column: 2/5;
          grid-row: 5/7;

          padding: 0 $gut/3*2;

        }

        @include after-in(md){

          grid-column: 4/7;
          // padding-top: 50%;

          position: relative;

          &::before{

            content: "";
            display: block;
            width: $full/3;
            padding-top: $full/3;

            background: color(lighter);

            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

            transform-origin: left top;
            transform: translate(-100%,100%);
          }
        }

        @include after-in(lg){

          grid-column: 4/6;

          align-self: center;
          margin-top: -50%;

          &::before{

            width: $full/2;
            padding-top: $full/2;

          }
        }
      }

      &-img3 {

        margin-top: $gut/3*4;

        @include after-in(sm){

          margin-top: 0;
          grid-column: 1/2;
          grid-row-start: 5;
        }

        @include after-in(md){

          grid-column: 1/3;
          margin-bottom: 50%;

          &::before{

            content: "";
            display: block;
            width: 100%;
            height: 100%;

            background: color(lighter);

            position: absolute;
            top: 100%;
            left: 0;
            z-index: -1;

            transform-origin: left top;
            transform: scale(.5) translate(0,0);
          }
        }
      }
    }
  }
}
