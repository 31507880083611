html, body {

  font-size: 10px;
  font-family: $text-font;
  font-weight: normal;
  font-weight: 300;
  line-height: size(line-height);
  @include font-smoothing;
  color: color(main);
}

.main-container {

  font-size: 1.6rem;
  line-height: 2.4rem;

  @include after-in(sm) {

    font-size: 1.8rem;
    line-height: 3.2rem;
  }
  // @include after-in(lg) {
  //
  //   font-size: 2.1rem;
  // }
}

h1,h2,h3,h4,h5,h6 {

  color: inherit;
}

a {

  text-decoration: none;
  color: inherit;

  &.link {

    color: color(second);

    border-bottom: .1rem solid color(second);
    font-weight: 600;

    &--white{

      color: color(white);
      border-color: color(white);
      font-weight: 400;
    }
  }
}

strong {

  font-weight: 600;
}

em, .italic {

  font-style: italic;
}

p {
  color: inherit;
}

p + p {

  margin-top: 1.6rem;
}

time {

  display: inline-block;
  font: inherit;
}

small {

  font-size: 75%;
}

.return {

  display: block;
}

//// ---- CUSTOM
