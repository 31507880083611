body.js-active{

  .js-video{

    &-vimeo{

      overflow: hidden;

      // background-color: color(lighter);
      background: url("https://i.vimeocdn.com/video/736033690.jpg") no-repeat center/cover;
      background: url($img + "home/hero" + $suffix + $jpg) no-repeat center/cover;

      &.show{

        iframe{

          opacity: 1;
        }
      }

      iframe {

        width: 100% !important;

        opacity: 0;

        position: absolute;
        top: 50%;
        transform: translate(0,-50%);

        transition: all .32s $authenticMotion .4s;
      }
    }
  }

  .vp-player-layout{

    width: 100% !important;
  }
}
