.overflow {

  overflow: hidden;
}

* {

  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;

  &, &:after, &:before {

    box-sizing: border-box;
  }
}

img {

  display: block;
  width: 100%;
  height: auto;

  image-rendering: -webkit-optimize-contrast;
}

body {

  min-height: 100vh;

  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  position: relative;
}


.main-container {

  width: 100vw;

  // height: .1rem;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;

  padding: 0 $gut;

  @include after-in(sm) {

    padding: 0 $gut*2;
  }

  // padding: 0 $gut*2 + $gut/3;


  // @include before-in(md) {
  //
  //   padding: 0 $gut*2;
  //   padding-top: $header-height-desk;
  // }
  // @include before-in(sm) {
  //
  //   padding: 0 $gut;
  //   padding-top: $header-height-desk;
  // }
  // @include before-in(xs) {
  //
  //   padding: 0 $gut/3*2;
  //   padding-top: $header-height-desk;
  // }
}

.main-content {

  width: 100%;
  min-height: 100vh;
  padding-top: $header-height-desk;
  position: relative;
}

.svg-defs {

  position: fixed;
  top: -100%;
  left: -100%;
}

button, input, textarea, select {

  &, &:focus {

    outline: none;
  }
}

//// ---- DEBUG

  a[href="#"], a[href=""], img[alt=""] {

    // border: .4rem solid lime !important;
  }


//// ---- CUSTOM
