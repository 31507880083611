&-grid{

  grid-template-rows: auto;

  @include before-out(sm) {

    grid-template-columns: $gut 1fr $gut;
    grid-auto-flow: row;
  }

  @include between(sm,md) {
    // grid-gap: $gut;
    grid-template-columns: $gut*2 1fr 1fr 1fr 1fr $gut*2;
  }

  @include after-in(md) {

    grid-template-columns: $gut*2 1fr 1fr 1fr 1fr 1fr 1fr $gut*2;
  }

  display: none;
  width: 100vw;
  height: 100vh;

  pointer-events: none;

  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  span{

    display: block;
    width: .1rem;
    height: 100%;

    background: color(light);
  }

  &--top {

    display: grid;

    pointer-events: auto;
    z-index: $z-curtain;

    transition: all .4s $authenticMotion;

    @include before-out(sm) {

      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-auto-flow: row;
    }

    span {

      width: calc(100% + .1rem);

      background: transparent;
      overflow: hidden;

      position: relative;

      transition: all .4s $easeInOutQuart;
      transform: scale(1) !important;

      &::after {

        content: "";
        display: block;
        width: 100%;
        height: 100%;

        background: color(light);

        position: absolute;
        top: 0;
        left: 0;
        transform-origin: left center;
        transform: scale(0,1);

        transition: inherit;
      }
    }

    @include before-out(sm) {

      span:nth-child(n+7) {

        display: none;
      }
    }
  }

  @include between(sm,md) {

    display: grid;

    span:nth-child(n+7) {

      display: none;
    }

    span:nth-child(6) {

      transform: translate(-100%,0);
    }
  }
  @include after-in(md) {

    display: grid;

    span {

      display: block;
    }

    span:last-child {

      transform: translate(-100%,0);
    }
  }
}
