&-news {
    &-list {
        grid-column: 1/6;
        @include after-in(md) {
            grid-column: 2/6;
        }
    }

    &-itm {
        position: relative;

        & + & {
            margin-top: $gut;
            @include after-in(sm) {
                margin-top: $gut*2;
            }
            @include after-in(md) {
                margin-top: $gut*4;
            }
        }

        &::after {
            @include before-out(sm) {
                padding-top: 100%;
            }
        }
    }

    &-article {
        // width: 100%;
        // height: 100%;
        // padding: $gut/3*2;
        background: color(second);
        color: color(white);

        &-wrapper{

            padding: $gut/3*2;

            @include after-in(sm) {

                padding: $gut;
            }
        }

        &-img{

            position: relative;

            @include before-in(xs){

                order: -1;
            }

            &::after{

                content: "";
                display: block;
                width: 100%;
                padding-top: 100%;
            }
        }

        // @include after-in(sm) {
        //
        //     // position: absolute;
        //     // bottom: 0;
        //
        //     // width: 50%;
        //     // height: 100%;
        //     padding: $gut;
        // }

        .title {
            color: color(white);
            // margin-bottom: $gut/3;
        }

        p {
            @include between(sm,lg) {
                font-size: 1.5rem;
                line-height: 2.1rem;
            }
            @include before-in(xs) {
                font-size: 1.4rem;
            }
        }

        .btn {
            margin-top: $gut/3;
            @include before-in(sm) {
                width: 100%;
            }
        }
    }
}
