&-section{

  & + &{

    margin-top: $gut*2;

    @include after-in(sm){

      margin-top: $gut*4;
    }

    // @include after-in(md) {
    //
    //   margin-top: $gut*6;
    // }
  }

  &-maintitle {

    @include after-in(sm){

      // margin-bottom: $gut*3;
      padding-left: calc(25% + #{$gut}/3*2);
    }

    @include after-in(md) {

      padding-left: calc(#{$full}/6 + #{$gut}/3*2);
      // margin-bottom: $gut*2;
    }

    @include after-in(lg) {

      // margin-bottom: $gut*4;
    }
  }

  &-sub-title{

    position: relative;
    z-index: 2;

    @include after-in(md){
      padding: 0 $gut/3*2;
      // margin-top: -$gut*2;
    }
  }

  &-hero{

    @include after-in(md){

      &::after{

        content: "";
        display: block;
        grid-column: 2/3;
        grid-row: 4/5;
        padding-top: 100%;

        background: color(second);

        position: relative;
        transform: translate(0,-50%);
      }
    }

    img{

      position: relative;
      z-index: -1;
      margin: $gut 0;

      @include after-in(sm){

        grid-row: 1/4;
        margin: $gut*2 0 0 0;
      }

      @include after-in(md){

        margin-top: $gut*3;
      }
    }

    &-text{

      padding: $gut;

      background: color(dark);

      color: color(white);

      @include after-in(sm){

        grid-column: 3/5;
        grid-row: 1;
      }
      @include after-in(sm){

        grid-column: 2/4;

        position: relative;
        margin-top: 15%;
      }
    }
  }

  &:last-of-type{

    margin-bottom: $gut;

    @include after-in(sm){

      margin-bottom: $gut*2;
    }
  }
}
