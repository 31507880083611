&-heading {

  &-title{

    &--simple{

      padding-top: $gut;
      padding-bottom: $gut;
      font-family: $text-font;
      font-weight: 300;

      @include after-in(sm){

        padding-top: $gut*2;
        padding-bottom: $gut*2;
      }
      @include after-in(md){

        padding-top: $gut*3;
        padding-bottom: $gut*3;
      }
      @include after-in(lg){

        font-size: 2.1rem;
        padding-top: $gut*4;
        padding-bottom: $gut*4;
      }
    }
  }

  &-hero {

    @extend %grid;
    max-height: 50vh;

    grid-template-rows: 1fr 1fr 1fr;
    // @include after-in(sm) {
    //
    // //
      // height: 40vw;
    // }

    @include after-in(md) {

      max-height: 65vh;
    }

    &-img {

      width: 100vw;
      // @include after-in(sm) {
        grid-row: 1/4;
      // }
      position: relative;
      z-index: -1;
    }

    &-text {

      grid-column: 1/4;
      padding: $gut;

      background: color(dark);

      color: color(white);

      position: relative;

      @include before-out(sm) {

        width: 100vw;
        justify-self: center;
      }

      @include after-in(sm) {

        grid-row: 3/4;
        grid-column: 3/5;
        padding-bottom: $gut*4;

        transform: translate(0,50%);

        &::before {

          content: "";
          display: block;
          width: 200vw;
          height: .1rem;

          background: color(light);

          position: absolute;
          top: 0; left: 50%;
          transform: translate(-50%,0);
        }
      }

      @include after-in(md) {

        grid-column: 4/6;
        padding-bottom: $gut;

        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }
  }
}
