.ratio {

  display: block;
  width: 100%;
  position: relative;

  &:after {

    content: "";
    display: block;
    width: 100%;
  }

  &--square {

    &:after {
      padding-top: $full;
    }
  }
  &--2x1 {

    &:after {
      padding-top: $full/2;
    }
  }

  &--5x2 {

    &:after {
      padding-top: $full/5*2;

      @include before-in(sm) {

        padding-top: $full/16*9;
      }
    }
  }

  &--3x1 {

    &:after {
      padding-top: $full/3;
    }
  }

  &--4x3 {

    &:after {
      padding-top: $full/4*3;
    }
  }

  &--16x9 {

    &:after {
      padding-top: $full/16*9;
    }
  }

  &--third {

    &:after {

      padding-top: $full_vh/2;

      @include after-in(sm) {

        padding-top: $full_vh/3*2;
      }
    }
  }
}
