&-article{

  &-wrapper{

  }

  &-header, &-content, &-footer, &-others{

    grid-column: 1/6;

    @include after-in(md){

      grid-column: 2/6;
    }
  }

  &-header{

    padding: 0 $gut/3*2;
    margin-top: $gut;

    @include after-in(md){

      margin-top: $gut*2;
    }

    &-img{

        margin-top: $gut/3*2;
        margin-left: -$gut/3*2;
        margin-right: -$gut/3*2;
    }
  }

  &-content{


    p{

      // max-width: 72rem;
    }

    .title{

      & + *{

        margin-top: $gut;
      }
    }

    a{

      color: color(second);
      border-bottom: .1rem solid color(second);
    }

    ul{

      padding-left: $gut/3*2;
      margin: $gut/3 0;

      li{

        position: relative;

        &::before{

          content: "";
          display: block;
          width: .4rem;
          height: .4rem;

          background: color(second);

          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-$gut/3*2,$gut/2 + .3rem);
        }
      }
    }
  }

  &-section{

    margin-top: $gut;

    @include after-in(md){

      margin-top: $gut*2;
    }
    @include after-in(lg){

      margin-top: $gut/3*7;
    }

    &--text{


      padding: 0 $gut/3*2;
    }
  }

  &-footer{

    &-btn{

      margin-top: $gut;

      @include after-in(md){

        margin-top: $gut*2;
      }
    }
  }

  &-infos{

    padding: $gut/2 0;
    margin-left: -$gut/2;
    margin-right: -$gut/2;

    position: relative;

    &::after, &::before{

      content: '';
      display: block;
      width: 100%;
      height: .1rem;
      padding: 0 $gut/2;

      background: color(grey);
      background-clip: content-box;

      position: absolute;
      left: 0;
    }

    &::after{
      top: 0;
    }

    &::before{
      bottom: 0;
    }

    & > div{

      margin: $gut/2;
    }

    .title{

      margin-right: $gut;
      color: color(black);
    }
  }

  &-tags{

    &-wrapper{


    }

    &-list{


    }

    &-itm{

      font-size: 1.5rem;
      line-height: 2.4rem;
      margin-right: $gut/3;

      a{

        border-bottom: .1rem solid color(grey);
      }

      &:last-child{

        margin-right: 0;
      }
    }
  }

  &-sharing{

    &-list{

      margin-left: $gut;
    }

    &-itm{

      & + &{

        margin-left: $gut/3;
      }

      button{

        display: block;
        width: $gut/3*4;
        height: $gut/3*4;

        background: color(dark);
        cursor: pointer;

        font-size: 1.4rem;
        color: color(white);

        position: relative;
      }

      i{

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }

  &-others{

    &-title{

      margin: $gut 0 $gut/3 0;
    }

    &-list{

      display: grid;
      grid-gap: .1rem;
      grid-template-columns: auto;
      grid-auto-flow: row;

      @include after-in(sm){

        grid-template-columns: auto auto;
      }
    }


    &-itm{

      // padding: 0 .1rem;

      position: relative;

      article{

        width: 100%;
        height: 100%;
        padding: $gut/3*2;

        background: rgba(color(second),.8);

        position: absolute;
        top: 0;
        left: 0;

        @include after-in(sm){

          padding: $gut;
        }
      }

      .title{

        color: color(white);
        margin-bottom: $gut/3;
      }
    }
  }
}
