&-job{

  &-title{

    margin-bottom: $gut/3*4;

    @include after-in(md){

      padding-left: $gut/3*2;
      margin-bottom: $gut/3*7;
    }
  }

  &-list{
  }

  &-itm{

    & + &{

      margin-top: $gut*2;

      @include after-in(md){
        margin-top: $gut/3*7;
      }
    }
  }

  &-content{

    @include after-in(sm){

      grid-column: 2/5;
      grid-row: 1/3;
      padding: 0 $gut/3*2 0 $gut/3*4;
    }

    @include after-in(md){

      grid-column: 3/6;
    }
    @include after-in(lg){

      grid-column: 3/5;
    }
  }

  &-subtitle{

    @include before-out(sm){
      font-size: 2.4rem;
    }

    margin-bottom: $gut/3;
  }

  &-btn{

    margin-top: $gut/3*2;
    @include after-in(sm){

      grid-column: 2/3;
    }
  }

  &-img{

    &-text{

      font-size: 1.8rem;
      line-height: 2.4rem;
      font-weight: 900;
      color: color(white);

      padding: $gut/3*2;

      position:absolute;
      bottom: 0;
      left: 0;

      @include after-in(lg){

        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }

    &::before{

      content: "";
      display: block;
      width: 100%;
      padding-top: 100%;

      background: color(second);
      opacity: .3;

      position: absolute;
      top: 0;
      left: 0;

      @supports (mix-blend-mode: color){
        mix-blend-mode: color;
        opacity: 1;
      }
    }

    @include before-out(sm){

      display: none;
    }

    @include after-in(sm){

      grid-column: 1/2;
      grid-row: 1/2;
    }

    @include after-in(md){

      grid-column: 2/3;
    }
  }
}
