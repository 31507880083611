html {

  &.ie, &.ie11 {

    .main-content {

      display: block;
    }
  }

  &.edge {

    .vtb-cards {

      &-item {

        // display: flex;
        // height: 100%;
        // padding-top: 200px;
        // box-sizing: content-box;
        // background-clip: content-box;
      }
    }
  }
}
