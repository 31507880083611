$columns:              12;
$full:                 100%;
$full_vw:              100vw;
$full_vh:              100vh;
$gut:                  2.4rem;

$breakpoints: (
  xxxs:                0px,
  xxsmid:              375px,
  xxs:                 440px,
  xsmid:               560px,
  xs:                  640px,
  sm:                  768px,
  md:                  1024px,
  lg:                  1366px
  // xl:                  1600px,
);

// between x and y

// inside with x
// inside not x

// outside with x
// outside not x

@function media($size) {
  @return map-get($breakpoints, $size);
}

@mixin before-out($size) {
  @media screen and (max-width: media($size) - 1) {
    @content;
  }
}

@mixin before-in($size) {
  @media screen and (max-width: media($size)) {
    @content;
  }
}

@mixin after-in($size) {
  @media screen and (min-width: media($size)) {
    @content;
  }
}
@mixin after-out($size) {
  @media screen and (min-width: media($size) + 1) {
    @content;
  }
}
@mixin hover() {
  @media screen and (min-width: media(md) + 1) {
    &:hover {
      @content;
    }
  }
}
@mixin hover-out() {
  @media screen and (min-width: media(md) + 1) {
    @content;
  }
}
@mixin between($s1,$s2) {
  @media screen and (min-width: media($s1)) and (max-width: media($s2) - 1) {
    @content;
  }
}


.line {

  // max-width: none !important;
  @extend %flex;
  // display: flex;
  // flex-wrap: wrap;
  // flex-direction: row;

  align-content: flex-start;

  &--right { justify-content: flex-end;}
  &--left { justify-content: flex-start;}
  &--bottom { align-content: flex-end; align-items: flex-end;}
  &--center { justify-content: center;}
  &--justify { justify-content: space-between;}
  &--v-center { align-content: center; align-items: center;}
  &--inline {display: inline-flex;}
  // &-bottom { align-items: flex-end;}
  // &-v-item {align-items: center;}
  // &-reverse { flex-direction: row-reverse;}
  &--col {flex-direction: column;}


  &--guts {

    margin-left: -$gut/3*4;
    margin-right: -$gut/3*4;

    @include before-in(md) {

      margin-left: -$gut/3*2;
      margin-right: -$gut/3*2;
    }


    & > * {

      // gutter 2.4rem
      padding-left: $gut/3*4;
      padding-right: $gut/3*4;

      @include before-in(md) {

        padding-left: $gut/3*2;
        padding-right: $gut/3*2;
      }

      // @include after-in(sm) {
      //
      //   // gutter 4.8rem
      //   padding-left: $gut;
      //   padding-right: $gut;
      // }
      // @include after-in(lg) {
      //
      //   padding-left: $gut*1.5;
      //   padding-right: $gut*1.5;
      // }
    }

    &[class^="flx"], &[class*=" flx"] {

      margin-left: 0;
      margin-right: 0;
    }
  }

  & > * {

    // width: $full;
    // max-width: $full;
    // flex: 0 0 $full;
  }

  &--full {

    & > * {

      width: $full;
      max-width: $full;
      flex: 0 0 $full;
    }
  }

  &--auto {

    & > * {

      flex: 0 0 auto;
    }
  }

}

.itm {

  &--center { align-self: center;}
  &--bottom {align-self: flex-end;}
  &--top {align-self: flex-start;}
  &--first {order: -1;}


  // @for $i from 1 through 3 {
  //   &--#{$i} {
  //     order: $i;
  //   }
  // }
}

// .fl-l {float: left;}
// .fl-r {float: right;}

// [class^="flx"], [class*=" flx"] {
//
//   margin-left: 0;
//   margin-right: 0;
// }

.grid {

  &-ctn {

    @extend %grid;
  }

  &-sub {

    // @extend %gridsub;
  }

  &-itm {

    &--c { justify-self: center; }

    grid-column: 1;

    // @include after-in(sm) {
    //
    //   &--x1 {grid-column-start: 2;}
    //   &--x2 {grid-column-start: 3;}
    //   &--x3 {grid-column-start: 4;}
    //   &--x4 {grid-column-start: 5;}
    //   &--x5 {grid-column-start: 6;}
    //   &--x6 {grid-column-start: 7;}
    // }

    @include after-in(sm) {

      &--text {
        padding-right: $gut/3*2;
        padding-left: $gut/3*2;
        grid-column: 3/5;
      }
    }


    &--full {

      grid-column: 1 / 4;

      @include between(sm,md) {

        grid-column: 1 / 7;
      }

      @include after-in(md) {

        grid-column: 1 / 9;
      }
    }

    &--expend {

      width: 100vw;
      justify-self: center;
      grid-column: 1 / 4;

      @include between(sm,md) {

        grid-column: 1 / 7;
      }

      @include after-in(md) {

        grid-column: 1 / 9;
      }
    }

    &--center {

      grid-column: 2/3;

      @include between(sm,md) {

        grid-column: 2 / 6;
      }

      @include after-in(md) {

        grid-column: 2 / 8;
      }
    }
  }
}

@each $breakpoint, $px in $breakpoints {
  @if ($breakpoint != "max") {
    @media screen and (min-width:$px) {

      .flx-#{$breakpoint}-auto {
        // width: auto;
        flex: 0 0 auto;
        // max-width: none;
      }

      @for $i from 1 through $columns {

        @if ($i > 1) {
          .flx-#{$breakpoint}-#{$i} {
            width: ($full/$columns)*$i;
            max-width: ($full/$columns)*$i;
            flex: 0 0 ($full/$columns)*$i;
          }
        }

        .ml-#{$breakpoint}-#{$i} {
          margin-left: ($full/$columns)*$i;
          // width: ($full/$columns)*$i;
          // max-width: ($full/$columns)*$i;
          // flex: 0 0 ($full/$columns)*$i;
        }

        // .#{$breakpoint}-#{$i} {
        //   display: block;
        //   width: ($full/$columns)*$i;
        //   float: left;
        //   margin-left: 0;
        //   margin-right: 0;
        // }
        // @if ($breakpoint != "xxxs") {
        //
        //   .flx-#{$breakpoint}-#{$i} {
        //     // width: ($full/$columns)*$i;
        //     max-width: ($full/$columns)*$i;
        //     flex: 0 0 ($full/$columns)*$i;
        //   }
        // }
        // @else if ($i != 12) {
        //   .flx-#{$breakpoint}-#{$i} {
        //     // width: ($full/$columns)*$i;
        //     max-width: ($full/$columns)*$i;
        //     flex: 0 0 ($full/$columns)*$i;
        //   }
        // }
      }

      // SPECIAL GRID SIZE
      // .flx-#{$breakpoint}-fifth {
      //
      //   width: $full/5;
      //   max-width: $full/5;
      //   flex: 0 0 $full/5;
      //   margin-left: 0;
      //   margin-right: 0;
      // }
    }
  }
}
// @each $breakpoint, $px in $breakpoints {
//   @media screen and (min-width:$px) {
//     @for $i from 1 through $columns {
//       .#{$breakpoint}-ml-#{$i} {
//         margin-left: ($full/$columns)*$i;
//       }
//       // .#{$breakpoint}-mr-#{$i} {
//       //   margin-right: ($full/$columns)*$i;
//       // }
//     }
//   }
// }
