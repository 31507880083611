$icons: (
  ("skill","security","database","settings","talk","gear")
);

$length-x: 11;
$length-y: 3;
$icons-x: $full/($length-x - 1);
$icons-y: $full/($length-y - 1);

.icon {

  display: inline-block;
  // flex: 0 0 auto;

  position: relative;
  transition: all .48s $authenticMotion;

  &::before {

    content: "";
    display: block;
    width: 100%;
    height: 100%;

    // opacity: 0;
    background-image: url($img + "icons/icons"+$suffix+$svg);
    background-size: $full*$length-x;
    // background-size: 4.8rem * $length-x;
    background-repeat: no-repeat;

    position: absolute;
    top: 0;
    transform-origin: center center;
  }

  // &:before {
  //
  //   opacity: 1;
  // }

  &--sm {

    &:before, &:after {
      transform: scale(.75);
    }
  }

  // &--active {
  //
  //   &:before {
  //     opacity: 0;
  //   }
  //
  //   &:after {
  //     opacity: 1;
  //   }
  // }

  // &--simple {
  //
  //   background-color: transparent !important;
  // }

  // &--white {
  //
  //   &:before {
  //
  //     opacity: 1;
  //   }
  //
  //   &:after {
  //
  //     opacity: 0;
  //   }
  // }
  //
  // &--ti {
  //
  //   text-indent: -999.9rem;
  // }
  //
  @each $item in $icons {
    @each $icon in $item {

      $x: index($icons,$item) - 1;
      $y: index($item,$icon) - 1;

      &--#{$icon} {

        &::before {

          background-position: ($x * $icons-x) ($y * $icons-y);
        }
      }
    }
  }

  // &--arrow-left {
  //
  //   width: 1.6rem !important;
  //   height: auto !important;
  //   margin-right: $gut/3;
  //
  //   background: none !important;
  //
  //   transition: all .48s $easeInOutAdj;
  //
  //   &:before {
  //
  //     width: 1.6rem;
  //     height: .2rem;
  //     margin: 0;
  //
  //     background: color(second);
  //     left: 0;
  //     top: 50%;
  //     transform: translate(0,-50%) !important;
  //     transition: all .48s $easeInOutAdj;
  //
  //   }
  //
  //   &:after {
  //
  //     content: "";
  //     display: block;
  //     width: .8rem;
  //     height: .8rem;
  //     border: .2rem solid color(second);
  //     border-bottom: 0;
  //     border-right: 0;
  //
  //     background: none;
  //     opacity: 1;
  //
  //     position: absolute;
  //     top: 50%;
  //     transform: translate(0,-50%) rotate(-45deg) !important;
  //     transition: all .48s $easeInOutAdj;
  //
  //   }
  // }
}

// custom

.icon {

  width: 4.8rem;
  height: 4.8rem;
}
