body.js-active{

  .js{

    &-is-focused{

      &::after{

        background: color(second);
      }

      .vtb-form-label{

        color: color(second);
        transform: scale(.75) translate(.4rem,-75%);
      }
    }

    &-is-filled{

      .vtb-form-label{

        transform: scale(.75) translate(.4rem,-75%);
      }
    }

    &-is-empty, &-is-invalid{

      &::after{

        background: color(alert);
      }
      .vtb-form-label{

        color: color(alert);
      }
    }
  }
}
