::selection {

  background: color(second);
  color: color(white);
}

.selection {

  &--invert {

    &::selection, ::selection {

      background: color(white);
      color: color(second);
    }
  }
}

.link {

  &::selection {

    background: darken(color(second),20%);
    color: color(white);
  }
}
