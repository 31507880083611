.main-header{

  width: 100vw;
  height: $header-height-desk;
  padding: $gut/2 $gut;
  // border-bottom: .1rem solid color(light);

  background: color(white);

  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-header;

  &::after{

    content: "";
    display: block;
    width: 100%;
    height: .1rem;

    background: color(light);

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: $z-nav;
  }

  @include after-in(sm){

    padding-left: $gut*2;
    padding-right: $gut*2;
  }

  &-logo{

    height: 4.8rem;
    padding-left: 5.6rem;

    font-weight: 900;
    font-size: 2rem;
    line-height: 3.2rem;
    color: color(main);

    position: relative;

    @include before-out(xxsmid){

      text-indent: -999.9rem;
    }

    span{

      display: inline-block;

      opacity: 0;

      position: absolute;
    }

    svg{

      display: block;
      width: 4.8rem;
      height: 4.8rem;

      position: absolute;
      top: 0; left: 0;
    }

    small{

      display: block;
      font-size: 1rem;
      line-height: .2rem;
      position: absolute;
      right: 0;
    }
  }

  &-langs{

    width: $gut*2;

    opacity: 0;
    overflow: hidden;
    pointer-events: none;

    position: absolute;
    z-index: $z-lang;
    top: $gut/2; right: $gut + $gut/3 + $gut*2;
    transition: all .4s $authenticMotion;

    @include after-in(sm){

      width: calc(100%/4 - #{$gut});
      // height: 100vh;
      padding-top: $header-height-desk + $gut/3*2;
      padding-bottom: $gut/3*2;

      opacity: 1;
      top: 0;
      right: calc(25% + #{$gut});

      &::before{

        content: "";
        display: block;
        width: 100%;
        height: 100%;

        background: color(lighter);

        position: absolute;
        z-index: -1;
        top: 0; left: 0;
        transform-origin: left center;
        transform: scale(0,1);

        transition: inherit;
      }
    }

    @include after-in(md){

      width: calc(100%/6 - #{$gut}/3*2);
      right: calc(100%/6 + #{$gut} + #{$gut}/3);
    }
  }

  &-lang{

    width: 100%;
    height: $gut*2;

    background: color(lighter);

    color: color(main);
    font-family: $title-font;
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;

    transition: transform .4s $authenticMotion;

    @include after-in(sm){

      height: auto;

      background: transparent;

      line-height: 3.2rem;
    }

    a{

      display: flex;
      height: 100%;
      justify-content: center;
      aline-items: center;

      transition: all .4s $authenticMotion;

      @include after-in(sm){

        padding: 0 $gut/3*2;
        justify-content: flex-start;

        transform: translate(-100%,0);
      }

      span{

        align-self: center;
      }

      @include hover{

        color: color(second);
      }
    }
  }

  &-menu{

    display: block;
    width: 100vw;
    height: 100vh;
    padding: inherit;
    padding-bottom: $gut;
    padding-top: $header-height-desk + $gut;

    background: color(white);
    opacity: 0;
    pointer-events: none;

    color: color(main);

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: opacity .4s $authenticMotion;


    @include after-in(sm){

      width: calc(100%/4 - #{$gut});

      padding-top: $header-height-desk + $gut/3*2;
      padding-left: 0;
      padding-right: 0;

      opacity: 1;

      background: transparent;

      color: rgba(black,.55);

      position: absolute;
      left: auto;
      right: $gut*2;

      &::before{

        content: "";
        display: block;
        width: 100%;
        height: 100%;

        background: color(second);
        box-shadow: 0 0 $gut*2 0 rgba(black,.15);

        position: absolute;
        top: 0;
        left: 0;
        transform-origin: left center;
        transform: scale(0,1);

        transition: transform .4s $authenticMotion;
      }
    }

    @include after-in(md){

      width: calc(100%/6 - #{$gut}/3*2);
    }
  }

  &-links{

    height: 100%;

    @include after-in(sm){

      // height: auto;
      flex-direction: row;
      height: auto;
    }
  }

  &-link{

    width: 100%;
    flex: 1 1 auto;

    overflow: hidden;

    position: relative;

    transition: all .4s $authenticMotion;
    transform: translate(0,$gut);

    & + &{

      margin-top: $gut/3;

      @include after-in(sm){

        margin-top: $gut/6;
      }
    }

    // @include before-out(sm) {
    //
    //   &:nth-child(even) {
    //
    //     a::before {
    //
    //       background: color(third);
    //     }
    //   }
    // }

    @include after-in(sm){

      transform: translate(0,0);
    }

    a{

      padding: 0 $gut*2;
      width: 100%;
      height: 100%;

      font-size: 2.4rem;
      line-height: 1;
      font-weight: 900;
      text-transform: lowercase;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      transform: translate(0,100%);
      // transition: all .4s $easeInOutFast;
      transition: all .4s $authenticMotion;

      &::before{

        content: "";
        display: block;
        width: 100%;
        height: 100%;

        background: color(light);

        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        transition: inherit;
      }

      &.active{

        color: color(white);

        &::before{

          background: color(second);
        }
      }

      @include after-in(sm){

        height: auto;
        padding: 0 $gut/3*2;

        opacity: 0;

        font-family: $title-font;
        font-weight: 400;
        line-height: 3.2rem;
        text-transform: uppercase;

        position: relative;

        transform: translate(-100%,0);

        &::before {

          display: none;
        }
      }

      @include hover{

        color: color(white);
        transition-delay: 0s !important;
      }
    }

    span{

      @include before-out(sm){

        &::before {

          content: attr(data-text);
          dispay: block;

          color: rgba(black,.05);

          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -1;
          transform-origin: left bottom;
          transform: scale(3.4) translate(0,.25rem);
        }
      }
    }
  }

  &.menu-open{

    .main-header{

      &-menu{

        opacity: 1;
        pointer-events: auto;

        &::before{

          transform: scale(1,1);
        }
      }

      &-link{
        transform: translate(0,0);
        a{
          opacity: 1;
          transform: translate(0,0);
          transition-delay: inherit;
        }
      }
    }
  }

  &.langs-open{

    .main-header{

      &-langs{

        pointer-events: auto;
        opacity: 1;

        @include before-out(sm){

          transform: translate(0,$gut*2 + $gut/3);
        }

        &::before{

          transform: scale(1,1);
        }

        a{

          transform: translate(0,0);
        }
      }
    }
  }
}
