body.js-active {

  .js-img {

    // overflow: hidden;

    background-size: cover;
    background-position: center;
    // background-clip: content-box;
    background-repeat: no-repeat;
    background-color: color(light);

    // &:after {
    //
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   padding-top: 65%;
    // }

    img {

      display: none;
    }
  }
}
