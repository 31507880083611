&-form{

  position: relative;

  grid-column: 1;
  // grid-row: 2;
  @include after-in(sm){

    grid-column: 3/5;
  }

  @include after-in(md){

    grid-column: 4/6;
  }

  @include before-out(sm){

    margin-top: $gut;
  }

  &-divider{

    position: relative;

    &::after{

      content: "";
      display: block;
      width: 100%;
      height: .1rem;

      background: color(grey);

      position: absolute;
      bottom: 0;
      left: 0;
    }

    &--submit{

      &::after{

        display: none;
      }
    }

    & + &{

      margin-top: $gut/3*2;
    }
  }

  &-input{

    &:focus{

      & + label{

        color: color(second);
        transform: scale(.75) translate(.4rem,-75%) ;
      }
    }

    &--submit{

      flex: 0 0 100%;
      //
      // &::after, &::before{
      //
      //   background: red;
      // }
    }

    &--honeypot{

        display: none;
    }
  }

  &-label{

    width: 100%;

    position: absolute;
    top: 0;
    left: 0;

    transform-origin: left bottom;
    transition: all .4s $authenticMotion;
  }

  &-message{

    display: flex;
    align-content: center; align-items: center;  justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    padding: $gut/3 0;

    background: color(dark);
    opacity: 0;
    visibility: hidden;

    color: color(white);

    position: absolute;
    top: 50%;
    left: 0;
    z-index: 20;

    transform-origin: center;
    transform: translate(0,-50%) scale(.98);

    transition: all .4s $authenticMotion;

    &.show{

      opacity: 1;
      visibility: visible;
      transform: translate(0,-50%) scale(1);
    }

    p{

      padding: $gut/3 $gut;
    }
  }
}
