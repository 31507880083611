&-service{

  @include after-in(sm){

    position: relative;

    &::after{

      content: "";
      display: block;
      width: 25%;
      padding-top: 25%;

      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;

      transform: translate(0,-25%);
    }

    &-1{
      &::after{
          display: none;
      //
      //   background: url($img + "misc/blue-screen" + $suffix + $jpg) no-repeat center/cover;
      }
    }
    &-2, &-3{
      &::after{
        display: none;
      }
    }
  }

  @include after-in(md){

    &::after{

      width: $full/6;
      padding-top: $full/6;
      right: $full/6;
    }

    &-1{

      &::after{
        transform: translate(0,-$gut*4 + $gut/3*2);
      }
      &::before{

        content: "";
        display: block;
        width: $full/6;
        padding-top: $full/6;

        background: url($img + "misc/team-screen" + $suffix + $jpg) no-repeat center/cover;

        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;

        transform: translate(0,-50%);

      }
    }

    &-2, &-3{
      &::after{
        display: block;
      }
    }

    &-2{
      &::after{
        top: auto;
        bottom: 25%;
        right: 0;
        background: url($img + "misc/keyboard-screen" + $suffix + $jpg) no-repeat center/cover;
        transform: translate(0,0);
      }
    }

    &-3{
      &::after{
        top: auto;
        bottom: 0;
        right: auto;
        left: 0;
        background: url($img + "misc/notebook-screen" + $suffix + $jpg) no-repeat center/cover;
        transform: translate(0,0);
      }
    }
  }

  &-title{

    padding: $gut/3*2 $gut;

    background: color(dark);

    color: color(white);

    margin-top: -$gut;
    margin-bottom: $gut;

    @include after-in(sm){

      padding: $gut/3*5 $gut*2;
      margin-top: -$gut*2;

      &::before{
        top: 100%;

      }
    }

    @include after-in(md){

      padding: $gut*3 $gut*3;
      margin-top: -$gut*4;

      &::before{
        left: -25%;
      }
    }
  }

  &-subtitle{

    font-weight: 400;

    @include after-in(sm){

      padding: $gut*2 $gut/3*2;
    }
  }

  &-hero{

    position: relative;
    z-index: -1;
  }

  &-list{

    margin-top: $gut;

    @include after-in(sm){

      padding: 0 $gut/3*2;
    }
  }

  &-cta{

    margin-top: $gut;

    &-title{

        margin: $gut 0;
        // margin-bottom: $gut;
    }
  }

  &-itm{

    & + &{

      margin-top: $gut*2;
    }

    .title{

      display: flex;
      align-items: center;
      margin-bottom: $gut/3*2;
    }

    .icon {

      display: block;
      flex: 0 0 4.8rem;
      margin-right: $gut;

      @include after-in(sm){

        position: absolute;
        transform: translate(-150%,0);
      }
    }

    ul{

      margin-top: $gut/3*2;
    }

    li{

      padding-left: $gut/3*2;

      position: relative;

      &::before{

        content: "";
        display: inline-block;
        width: .8rem;
        height: .8rem;
        margin-right: -.8rem;
        border: .1rem solid color(main);

        border-radius: 100rem;

        position: relative;
        top: 0;
        left: 0;
        transform: translate(-200%,-.1rem);
      }
    }
  }
  &-contrats{
    display: flex;
    flex-wrap: wrap;

    &-elem{
      position:relative;
      overflow: hidden;

      a{
        display: block;
        background: color(dark);
        overflow: hidden;
        color: color(white);
        transition: background 0.3s $authenticMotion;
        position: relative;
        top: -6rem;
        padding: 3rem;
        font-weight: 600;
        font-size: 2.2rem;

        span{
          display: block;
          position: relative;
          z-index: 2;
        }

        &:after{
          content: attr(data-title);
          display: block;
          color: color(darker);
          font-size: 16rem;
          font-weight: 700;
          transition: color 0.3s $authenticMotion;
          position: relative;
          z-index: 1;
          line-height: 6rem;
          top: -3rem;
        }

        &:hover{
          background: color(second);

          &:after{
            color: color(third);
          }

        }
      }

    }

    @include after-in(sm){
      .grid-itm--expend{
        width: 50vw;
      }
      
    }
    
  }
}
