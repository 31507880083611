&-partners{

  &-list{

    // margin-top:;
    margin-bottom: -$gut/3;
    margin-left: -$gut/3*2;
    margin-right: -$gut/3*2;
  }

  &-itm{

    padding: $gut/3 $gut/3*2;
  }

  &-img{

    background-color: transparent !important;
    background-size: 300% !important;
    background-position: 0 0 !important;

    &::after{

      content: "";
      display: block;
      width: 100%;
      padding-top: 78.125%;
    }

    &--dellemc{

      background-position: 50% 0 !important;
    }
    &--veeam{

      background-position: 100% 0 !important;
    }
    &--microsoft{

      background-position: 0 100% !important;
    }
    &--hewlett{

      background-position: 50% 100% !important;
    }
    &--dell{

      background-position: 100% 100% !important;
    }
  }
}
