.hugggy-logo {

  display: block;
  width: 3.2rem;
  height: 3.2rem;
  margin-left: auto;

  opacity: 1;
  // mix-blend-mode: multiply;

  // float: right;
  transform-origin: right center;
  transition: all .6s $easeInOutQuint;

  // position: relative;
  // top: 50%;
  // transform: translate(0,-50%);

  @include hover {

    transform: translate(0,-50%) scale(1.1);

    svg path {

      fill: color(white);
    }
  }

  span {

    display: block;
    width: 0;
    height: 0;

    opacity: 0;

    text-indent: -9999px;
  }

  svg {

    width: 100%;
    height: 100%;

    path {

      fill: color(main);

      transition: all .6s $easeInOutQuint;
    }
  }
}
