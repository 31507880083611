@mixin btn($first, $second) {

  color: $second;
  border: .1rem solid $first;

  &::before, &::after, span::after, span::before{

    background: $first;
    transform: scale(1,1);
  }

  @include hover{

    color: $first;

    &::before, &::after, span::after, span::before{

      transform: scale(0,1);
    }
  }

  &.btn--outline{

    color: $first;
    background: transparent;
    border: .1rem solid $first;

    &::before, &::after, span::after, span::before{

      transform: scale(0,1);
    }

    @include hover{

      color: $second;

      &::before, &::after, span::after, span::before{

        background: $first;
        transform: scale(1,1);
      }
    }
  }
}


.btn{

  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: $full;

  padding: $gut/3 $gut*2;

  cursor: pointer;
  user-select: none;
  overflow: hidden;

  font-family: $title-font;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;

  position: relative;
  z-index: 2;

  transition: all .24s $authenticMotion;

  @include after-in(sm){

    font-size: 2.1rem;
    padding: $gut/3 $gut*3;
  }


  &::before, &::after, span::after, span::before{

    content: "";
    display: block;
    width: calc(25% + .1rem);
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;

    transform-origin: left center;
    transform: scale(0,1);

    transition: all .24s $authenticMotion;
  }

  &::after {

    left: 25%;
    transition-delay: .03s;
  }

  span::before {

    left: 50%;
    transition-delay: .03s*2;
  }

  span::after {

    left: 75%;
    transition-delay: .03s*3;
  }


  // &--right, &--left {
  //
  //   span {
  //
  //     &::after {
  //
  //       content: "";
  //       display: block;
  //       width: 1.2rem;
  //       height: 1.2rem;
  //       border-width: .2rem;
  //       border-style: solid;
  //
  //       position: absolute;
  //       top: 50%;
  //       z-index: 10;
  //
  //       transition: inherit;
  //     }
  //   }
  // }
  //
  // &--right {
  //
  //   span {
  //
  //     &::after {
  //
  //       border-bottom: 0;
  //       border-left: 0;
  //
  //       right: 0;
  //       transform: translate(400%,-50%) rotate(45deg);
  //     }
  //   }
  //
  //   @include hover {
  //
  //     &::before, &::after, span {
  //
  //       transform: translate($gut/3,0);
  //     }
  //   }
  // }
  //
  // &--left {
  //
  //   span {
  //
  //     &::after {
  //
  //       border-bottom: 0;
  //       border-right: 0;
  //
  //       left: 0;
  //       transform: translate(-400%,-50%) rotate(-45deg);
  //     }
  //   }
  //
  //   @include hover {
  //
  //     &::before, &::after, span {
  //
  //       transform: translate(-$gut/3,0);
  //     }
  //   }
  // }

  &--grow {

    @include before-in(xxsmid) {

      width: 100%;
    }
  }

  &--sm {

    padding: $gut/3 $gut/2;

    font-size: 1.8rem;
    line-height: 2.4rem;

    span::after {

      width: .8rem;
      height: .8rem;
    }
  }

  &--white{

    @include btn(color(white),color(dark));
  }
  &--grey{

    @include btn(color(dark),color(white));
  }
  &--blue{

    @include btn(color(second),color(white));
  }


  &-wrapper {

    padding: $gut*3 0 0;
  }

  // &-arrow {
  //
  //   display: block;
  //   width: $gut*2.5;
  //   height: $gut*2.5;
  //
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   z-index: 3;
  //
  //   transition: all .32s $authenticMotion;
  //
  //   &::before {
  //
  //     content: "";
  //     display: block;
  //     width: $gut/3*2;
  //     height: .2rem;
  //
  //     background: color(second);
  //
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%,-50%);
  //   }
  //
  //   &::after {
  //
  //     content: "";
  //     display: block;
  //     width: $gut/3;
  //     height: $gut/3;
  //     border: .2rem solid color(second);
  //     border-left: 0;
  //     border-bottom: 0;
  //
  //
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(0,-50%) rotate(45deg);
  //   }
  //
  //   &--green {
  //
  //     &::before {
  //
  //       background: color(white);
  //     }
  //
  //     &::after {
  //
  //       border-color: color(white);
  //     }
  //   }
  // }

  &-lang{

    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.8rem;
    height: 4.8rem;
    margin-left: auto;
    margin-right: .8rem;

    background: color(light);
    cursor: pointer;

    color: color(third);
    font-family: $title-font;
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;

    position: relative;
    z-index: $z-lang;

    @include after-in(sm){

      justify-content: flex-start;

      margin-right: 0;
      height: $header-height-desk;
      transform: translate(0,-$gut/2);

      span{

        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate($gut/3*2,-$gut/3*2);
      }
    }
  }

  &-menu {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.8rem;
    height: 4.8rem;

    background: color(second);
    cursor: pointer;

    font-family: $title-font;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;

    position: relative;

    transition: all .32s $authenticMotion;

    @include after-in(sm) {

      // width: 25%;
      // flex: 0 0 25%;
      align-items: flex-end;
      align-content: flex-end;
      height: $header-height-desk;
      transform: translate(0,-$gut/2);
    }

    i {

      transition: inherit;
    }

    span, i::before, i::after {

      display: block;
      width: 2rem;
      height: .2rem;

      background: color(white);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      transition: inherit;
    }

    i::before, i::after {

      @include after-in(sm) {

        left: auto;
        right: 0;
        transform: translate(0,-50%);
      }
    }

    i::after {

      content: "";

      opacity: 0;
    }

    i::before {

      content: "";
    }

    span {

      background: transparent;

      color: color(white);

      @include before-out(sm) {

        text-indent: -999.9rem;
      }

      @include after-in(sm) {

        display: inline-block;
        width:  auto;
        height: auto;
        padding-right: $gut/3*4;
        left: $gut/3*2;
        top: auto;
        bottom: $gut/3*2;
        transform: translate(0,0);
      }

      &::before, &::after {

        content: "";
        display: block;
        width: 100%;
        height: 100%;

        background: color(white);

        position: absolute;
        top: 0;
        left: 0;

        transition: inherit;

        @include after-in(sm) {

          width: 2rem;
          height: .2rem;
          top: 50%;
          left: auto;
          right: 0;
        }
      }

      &::before {

        transform: translate(0,-300%);

        @include after-in(sm) {

          transform: translate(0,-350%);
        }
      }

      &::after {

        transform: translate(0,300%);
        @include after-in(sm) {

          transform: translate(0,250%);
        }
      }
    }
  }
}

///// CHANGING STATE

.menu-open {

  .btn-menu {

    @include before-out(sm) {
      background: color(third);
    }

    span {

      &::before, &::after {

        opacity: 0;
      }

      &::before {

        transform: translate(0,-600%);
      }
      &::after {

        transform: translate(0,600%);
      }
    }

    i::after, i::before {

      transition-delay: .1s;
    }

    i::after {

      opacity: 1;
      transform: translate(-50%,-50%) rotate(45deg);

      @include after-in(sm) {

        transform: translate(0,-50%) rotate(45deg);
      }
    }

    i::before {

      transform: translate(-50%,-50%) rotate(-45deg);

      @include after-in(sm) {

        transform: translate(0,-50%) rotate(-45deg);
      }
    }
  }
}
