body.team {

  .vtb{

    &-section{

      &-sub-title{

        @include after-in(sm){

          padding-left: $gut/3*2;
          padding-right: $gut/3*2;
          grid-column: 1/4;
          grid-row: 2/4;
        }

        @include after-in(md){

          grid-column: 2/5;
        }
        @include after-in(lg){

          grid-column: 2/4;
        }
      }
    }
  }
}
