.main-footer {

  padding: $gut 0 0 0;
  background: color(dark);

  color: color(white);
  font-weight: 400;
  font-size: 1.6rem;

  position: relative;

  @include after-in(md) {

    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  @include after-in(lg) {

    font-size: 1.6rem;
  }

  p + p {

    // margin-top: 0;
  }
  &-icon{

    padding-left: $gut;

    position: relative;

    &::before{

      content: "";
      display: inline-block;
      width: $gut/3*2;
      height: $gut/3*2;
      margin-right: 0;

      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0,-60%);
    }

    &--in{

        &::before{
            background: url($img + "icons/linkedin" + $suffix + $svg) no-repeat center/cover;
        }
    }
    &--tw{

        &::before{
            background: url($img + "icons/twitter" + $suffix + $svg) no-repeat center/cover;
        }
    }
  }

  &, &-legal {

    // width: 100vw;
    // justify-self: center;

    position: relative;
    z-index: 2;

    &::before {

      content: "";
      display: block;
      width: 100vw;
      height: 100%;

      background: inherit;

      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translate(-50%,0);
    }

    @include before-out(sm) {

      grid-column: 1/4;
    }
  }

  &-adress {

    @include after-in(sm) {

      grid-column: 1/3;
    }

    @include after-in(md) {

      grid-column: 1/4;
    }
  }

  &-partners {

    @include before-out(sm) {

      margin-top: $gut;
    }

    @include after-in(sm) {

      grid-column: 3/5;
    }

    @include after-in(md) {

      grid-column: 5/7;
    }
  }

  .partners-logos {
    align-items: center;
    margin: calc(1.6rem - #{$gut}) -#{$gut} 0;

    p {
      padding: $gut;
    }

    p + p {
      margin-top: 0;
    }
  }

  .partner-logo {
    height: auto;
    width: auto;
    max-width: 80%;
    max-height: 80%;
  }

  &-title {

    color: color(second);
    font-weight: 900;
    font-size: 1.8rem;
  }

  &-legal {

    padding: $gut 0;
    margin-top: $gut;
    background: color(darker);

    @include after-in(sm) {

      grid-column: 1/5;
    }

    @include after-in(md) {

      grid-column: 1/7;
    }
  }

  small {

    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}
