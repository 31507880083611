&-anchors {

  &-wrapper{


    margin-top: $gut;

    @include after-in(sm){

      margin-top: $gut*2;
    }
  }

  &-list{

    @include between(sm,md){
      grid-gap: $gut/3;
    }
  }

  &-itm{

    padding: $gut $gut/3*2;

    background: color(dark);

    text-align: center;
    color: lighten(color(second),30%);

    & + &{

      @include before-out(sm){

        margin-top: $gut/3;
      }
    }


    @include after-in(sm){

      &:first-child{

        grid-column: 1/3;
      }

      &:nth-child(2){

        grid-column: 3/5;
      }

      &:last-child{

        grid-column: 2/4;
      }
    }

    @include after-in(md){

      padding: $gut*5 $gut;

      &:last-child{

        grid-column: 5/7;
      }
    }

    .btn{

      margin-top: $gut/3;
    }
  }

  &-title{

    color: inherit;
  }
}
