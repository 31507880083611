%guts-h {


  // padding-left: $gut + $gut/3*2;
  // padding-right: $gut + $gut/3*2;
  padding-left: $gut;
  padding-right: $gut;

  @include between(xxxs,xxs) {

    // padding-left: $gut;
    // padding-right: $gut;
  }

  @include after-in(md) {

    // padding-left: $gut*3;
    // padding-right: $gut*3;
  }
}

%guts-v {

  padding-top: $gut + $gut/3*2;
  padding-bottom: $gut + $gut/3*2;

  @include between(xxxs,xxsmid) {

    padding-top: $gut + $gut/3*2;
    padding-bottom: $gut + $gut/3*2;
  }

  @include after-in(md) {

    padding-top: $gut*3;
    padding-bottom: $gut*3;
  }
}

%negate-guts {

  margin-left: -$gut - $gut/3*2;
  margin-right: -$gut - $gut/3*2;

  @include between(xxxs,xxsmid) {

    margin-left: -$gut;
    margin-right: -$gut;
  }

  @include after-in(md) {

    margin-left: -$gut*3;
    margin-right: -$gut*3;
  }
}

%expend {

  // margin-left: -$gut;
  // margin-right: -$gut;
  // max-width: none;
  // //
  // // position: relative;
  // // left: 50%;
  // // transform: translate(-50%,0);
  //
  // @include after-in(sm) {
  //
  //   margin-left: -$gut*2;
  //   margin-right: -$gut*2;
  // }

  // margin-left: -$gut/2;
  // margin-right: -$gut/2;
  //
  // @include after-in(sm) {
  //
  //   margin-left: -$gut;
  //   margin-right: -$gut;
  // }
  //
  // @include after-in(lg) {
  //
  //   margin-left: -$gut*1.5;
  //   margin-right: -$gut*1.5;
  // }

  transform: translate(-$gut/2,0);

  @include after-in(sm) {
    // left: -35vw;
    transform: translate(-$gut,0);
  }
  @include after-in(md) {

    // left: -25vw;
    transform: translate(-$gut*2,0);
  }
}

%box-sizing {


}

%flex {

  display: flex;
  flex-wrap: wrap;
}

%flex-auto {

  width: auto;
  flex: 0 0 auto;
  max-width: none;
}

// %grid {
//
//   display: grid;
//   grid-template-rows: auto;
//
//   @include between(xxxs,sm) {
//
//     grid-template-columns: $gut 1fr $gut;
//     grid-auto-flow: row;
//   }
//
//   @include between(sm,md) {
//     // grid-gap: $gut;
//     grid-template-columns: $gut*2 1fr 1fr 1fr 1fr $gut*2;
//   }
//
//   @include after-in(md) {
//
//     grid-template-columns: $gut*2 1fr 1fr 1fr 1fr 1fr 1fr $gut*2;
//   }
// }

%grid {

  display: grid;
  grid-template-rows: auto;

  @include between(xxxs,sm) {

    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  @include between(sm,md) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include after-in(md) {

    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
