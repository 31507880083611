&-pagination{

  &-wrapper{

    grid-column: 1/6;
    border-top: .1rem solid color(grey);
    margin-top: $gut/3*5;

    @include after-in(md){

      margin-top: $gut*2;
      grid-column: 2/6;
    }
  }

  &-list{

    transform: translate(-$gut/3,0);
  }

  &-itm{

    position: relative;

    & + &{
      &::after{

        content: "";
        display: block;
        width: .4rem;
        height: .1rem;

        background: color(grey);

        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%,0);
      }
    }

    a{

      display: block;
      padding: $gut $gut/3;

      &.active{
        font-weight: bold;
        color: color(second);
      }
    }
  }
}
