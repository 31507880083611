body.career{

  .vtb{

    &-section{

      position: relative;

      & + .vtb-section{

        @include after-in(md){

          margin-top: 0 !important;
        }
      }

      &-img{

        margin-top: $gut;

        @include after-in(sm){

          grid-column: 4/5;
          grid-row: 2/3;
          margin-top: 0;

          position: relative;
          // bottom: 0;
          // right: 0;
        }

        @include after-in(md){

          // margin-bottom: 50%;
          grid-column: 5/7;

          position: relative;

          // &::before{
          //
          //   content: '';
          //   display: block;
          //   width: 100%;
          //   padding-top: 100%;
          //
          //   background: color(second);
          //
          //   position: absolute;
          //   bottom: 0; left: 0;
          //   transform-origin: right top;
          //   transform: translate(-100%,100%) scale(.5);
          // }
        }
      }

      &-sub-title {

        @include after-in(sm){

          padding-left: $gut/3*2;
          padding-right: $gut/3*2;
          grid-column: 1/4;
          grid-row: 2/4;
        }

        @include after-in(md){

          grid-column: 1/4;
        }

        @include after-in(lg){

          grid-column: 2/4;
        }
      }
    }
  }
}
