body.contact {
    .vtb {
        &-section {
            @include after-in(md) {
                margin-bottom: 0;
            }

            &-sub-title {
                @include after-in(sm) {
                    grid-column: 1/3;
                }
                @include after-in(md) {
                    grid-column: 2/4;
                }
            }

            &-hero {
                &::after {
                    display: none;
                }

                &-text {
                    grid-column: 1/3;
                    @include after-in(md) {
                        grid-column: 2/4;
                    }
                }
            }
        }
    }
}


.flex-column{
    display: flex;
    flex-direction: column;

    .space-grow{
        flex-grow: 1;
    }
    .btn{
        margin-top: 3rem;

        @include after-in(md) {
            margin-left: -1.6rem;
        }
    }
}