&-team{

  &-apply{

    padding: $gut;
    padding-top: $gut/3*5;
    margin-top: $gut;

    background: url($img + "misc/blue-screen" + $suffix + $jpg) no-repeat center/cover;

    color: color(white);
    font-weight: 900;
    font-size: 2.1rem;
    line-height: 3.2rem;

    @include after-in(sm){

      margin-top: $gut*2;
      grid-column: 3/5;
      grid-row: 4;
    }

    @include after-in(md){

      margin-top: 0;
      grid-column: 5/7;
    }
    @include after-in(lg){

      padding-top: $gut*4;
      grid-column: 5/6;
    }
  }

  &-list{

    margin-top: $gut;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: none;
    grid-auto-rows: 1fr;
    grid-auto-flow: row dense;

    &:before{
      content: '';
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }

    & > *:first-child{
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }

    @include after-in(sm){

      margin-top: $gut*2;
      grid-gap: .1rem;
      grid-template-columns: repeat(4, 1fr);
    }

    @include after-in(md) {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &-img{

    width: 100%;
    height: 100%;

    background-clip: border-box;
    filter: grayscale(100%);

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &-itm{
    grid-column: 1;
    position: relative;
    z-index: 2;
    text-align: left;
    color: color(white);

    &::before{

      content: "";
      display: none;
      width: 100%;
      height: 100%;

      background: color(lighter);

      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    @include after-in(sm){
      grid-column: auto;

      &.wide{
        grid-column: auto / span 2;
        grid-row: auto / span 2;
      }

      &.offset-top{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-row: auto / span 2;

        &:after{
          content: ' ';
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          order: 1;
        }

        .vtb-team-wrapper{
          position: relative;
          order: 2;
        }

        &.wide{
          grid-template-rows: 1fr 2fr;
          grid-row: auto / span 3;
        }
      }
    }

    /*@include after-in(sm){

      &:first-child{

        grid-column: 1/3;
        grid-row: 1/3;
      }
      &:nth-child(2){

        grid-column: 3/5;
        grid-row: 2/4;
      }
      &:nth-child(3){

        grid-column: 1/3;
        grid-row: 4/6;

        &::before{

          display: block;
          transform-origin: left bottom;
          transform: translate(0,-100%) scale(.5);
        }
      }
      &:nth-child(4){

        grid-column: 3/4;
        grid-row: 1;
      }
      &:last-child{

        grid-column: 4/5;
        grid-row: 4;

        &::before{

          display: block;
          transform: translate(0,100%);
        }
      }
    }

    @include after-in(md){

      &:first-child{

        grid-column: 1/3;
        grid-row: 1/3;
        &::before{

          display: block;
          transform-origin: left bottom;
          transform: translate(0,50%) scale(.5);
        }
      }
      &:nth-child(2){

        grid-column: 5/7;
        grid-row: 1/3;
      }
      &:nth-child(3){

        grid-column: 3/5;
        grid-row: 2/4;
      }
      &:nth-child(4){

        grid-column: 4/5;
        grid-row: 1;
      }
      &:last-child{

        grid-column: 4/5;
        grid-row: 4;

        &::before{

          transform: translate(200%,0);
        }
      }
    }*/

    & + &{

      @include before-out(sm){
        margin-top: .1rem;
      }
    }

    &.min{

      .title{

        font-size: 2.1rem;
        line-height: 2.4rem;
        @include after-in(lg){
          font-size: 2.4rem;
          line-height: 3.2rem;
        }
      }

      p{

        font-size: 1.4rem;
        line-height: 1;

        @include after-in(lg){
          font-size: 1.8rem;
          line-height: 2.4rem;
        }
      }
    }

    .title{

      font-size: 2.4rem;
      line-height: 3.2rem;
      color: color(white);

      @include after-in(lg){

        font-size: 3.2rem;
        line-height: 4rem;
      }
    }

    p{

      font-weight: 700;
    }

    .title, p{

      position: relative;
      z-index: 3;

      overflow: hidden;

      span{

        display: inline-block;

        transition: all .4s $authenticMotion;

        @include hover-out{

          transform: translate(0,100%);
        }
      }
    }

    .vtb-team-wrapper{

      width: 100%;
      height: 100%;
      padding: $gut;

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;

      &::after{

        content: "";
        display: block;
        width: 100%;
        height: 100%;

        background: rgba(color(second),.5);

        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        transition: all .32s $authenticMotion;

        @include hover-out{

          transform-origin: left bottom;
          transform: scale(0,1);
        }
      }

      @include hover{

        span{

          transform: translate(0,0);
        }

        &::after {

          transform: scale(1);
        }
      }
    }
  }
}
