body.job{

  .vtb{

    &-job {

      position: relative;

      &-title {

        @include after-in(sm){

          margin-bottom: $gut*3;
          padding-left: calc(25% + #{$gut}/3*2);
        }

        @include after-in(md) {

          padding-left: calc(#{$full}/6 + #{$gut}/3*2);
          margin-bottom: $gut*2;
        }

        @include after-in(lg) {

          margin-bottom: $gut*4;
        }
      }
    }

    &-section {

      position: relative;
      @include after-in(sm) {
        padding: $gut/3*2;
      }

      h2 {
        @extend .title;
        @extend .title--second;
        padding: ($gut/3*2)*2 0 $gut/3*2;

        &:first-child {
          padding-top: 0;
        }
      }

      h3 {
        @extend .title;
        margin-top: 0.5em;
        font-size: 130%;
      }

      a {
        color: color(second);
        text-decoration: underline;
        transition: 0.2s color $easeInSine;

        &:hover,
        &:focus {
          color: lighten(color(second),20%);
        }
      }

      ul {
        margin: 0.5em 0 0.5em 1em;

        li {
          list-style: circle;

          + li {
            margin-top: 0.5em;
          }
        }

        li > ul > li {
          list-style-type: disc;
        }
      }

      p + p {
        margin-top: $gut/3*2;
      }

      @include after-in(md) {
        grid-column: 2 / 5;
      }

      & + .vtb-section{

        @include after-in(md){

          margin-top: 0 !important;
        }
      }
    }
  }
}
